import { STAFF, ADMIN } from '@/auth/roles';

import AgentIndex from '@/views/agent/Index';
import AgentService from '@/views/agent/service/Index';
import AgentServiceDetail from '@/views/agent/service/Detail';
import AgentServicePositionDetail from '@/views/agent/service/position/Detail';

export default [
  {
    path: '/agent',
    name: 'agentIndex',
    component: AgentIndex,
    meta: {
      authorize: [STAFF, ADMIN],
      title: 'nav.agent.index',
    },
  },
  {
    path: '/agent/service',
    name: 'agentService',
    component: AgentService,
    meta: {
      authorize: [STAFF, ADMIN],
      title: 'nav.agent.service',
    },
  },
  {
    path: '/agent/service/:order',
    name: 'agentServiceDetail',
    component: AgentServiceDetail,
    meta: {
      authorize: [STAFF, ADMIN],
      title: 'nav.agent.serviceDeatil',
    },
  },
  {
    path: '/agent/service/:order/position/:pos',
    name: 'agentServicePositionDetail',
    component: AgentServicePositionDetail,
    meta: {
      authorize: [STAFF, ADMIN],
      title: 'nav.agent.servicePositionDeatil',
    },
  },
];
