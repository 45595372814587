import Vue from 'vue';
import Vuex from 'vuex';

import Customer from '@/store/modules/Customer.store';
import User from '@/store/modules/User.store';
import ServiceOrder from '@/store/modules/ServiceOrder.store';
import Loading from '@/store/modules/Loading.store';

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    Customer,
    User,
    ServiceOrder,
    Loading,
  },
});
