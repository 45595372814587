<template>
  <div class="page-footer__main container">
    <div>&copy; Suspension Center {{ currentYear }}</div>
    <div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/settings';

.page-footer {
  background-color: $--color-dark-gray;
  color: darken($--color-light-gray, 25%);
}
.page-footer__main {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
