<template>
  <main-page
    :page-title="
      $t('page.dashboard.greeting') + ' ' + user.customer.contactName()
    "
  >
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <router-link :to="{ name: 'customerOrderCreate' }">
          <el-button type="primary" size="small">{{
            $t('page.dashboard.control.createServiceOrder')
          }}</el-button>
        </router-link>
      </div>
    </template>
  </main-page>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('User', ['user']),
  },
};
</script>
