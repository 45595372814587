<template>
  <main-page :page-title="$t('page.editAccount.title')">
    <CustomerEditForm :user="user" />
  </main-page>
</template>
<script>
import CustomerEditForm from '@/components/CustomerEditForm';
import { mapState } from 'vuex';

export default {
  components: {
    CustomerEditForm,
  },
  computed: {
    ...mapState('User', ['user']),
  },
};
</script>
