<template>
  <el-col :span="24">
    <div class="service" :class="{ 'service--selected': mode === 'display' }">
      <div class="service__head">
        <div class="service__details">
          <span class="h-text-bold">{{ service.name }}</span>
          <span>{{ service.description }}</span>
          <span><i class="el-icon-money"></i> CHF {{ service.price }}</span>
        </div>
        <div class="service__controls">
          <el-button
            v-if="mode === 'select'"
            size="mini"
            type="primary"
            @click="selectService(service)"
            >{{ $t('form.service.controls.chooseService') }}</el-button
          >
          <el-button
            v-else
            size="mini"
            type="primary"
            @click="deselectService()"
            >{{ $t('form.service.controls.changeService') }}</el-button
          >
        </div>
      </div>
      <div v-if="mode === 'display'" class="service__form">
        <slot name="position-form"></slot>
      </div>
    </div>
  </el-col>
</template>
<script>
export default {
  props: {
    service: {
      type: Object,
      default: null,
      required: true,
    },
    mode: {
      type: String,
      default: 'select',
      required: false,
    },
  },
  methods: {
    selectService(service) {
      this.$emit('selectService', service);
    },
    deselectService() {
      this.$emit('deselectService');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/settings';
.service {
  background-color: $--color-light-gray;
  border-left: 0.4rem solid $--color-dark-gray;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  &--selected {
    border-color: $--color-primary;
  }
}
.service__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.service__details {
  color: $--color-gray;
  span {
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.service__controls {
  text-align: right;
  .control:not(last-of-type) {
    margin-right: 0.15rem;
  }
}
</style>
