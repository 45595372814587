<template>
  <div class="position-form">
    <el-row :gutter="20">
      <el-col :span="12">
        <h2>{{ $t('page.servicePosition.sectionTitle.serviceTypes') }}</h2>
        <el-radio-group
          v-model="selectedServiceType"
          @change="handleManufactureLoading"
        >
          <el-radio
            v-for="(service, key) in serviceTypes"
            :key="key"
            :label="key"
            border
            >{{ service.title }}</el-radio
          >
        </el-radio-group>
      </el-col>

      <el-col :span="12">
        <h2>{{ $t('page.servicePosition.sectionTitle.manufactures') }}</h2>
        <el-alert
          v-if="!selectedServiceType"
          :title="$t('page.servicePosition.messages.noType')"
          type="info"
          :closable="false"
          show-icon
        ></el-alert>
        <el-row v-else v-loading="manufactureLoading">
          <div class="manufactures-list">
            <el-radio-group
              v-if="manufactures.length"
              v-model="selectedManufacture"
              @change="handleServiceLoading"
            >
              <el-radio
                v-for="(manufacture, key) in manufactures"
                :key="key"
                :label="manufacture"
                border
                >{{ manufacture.name }}</el-radio
              >
            </el-radio-group>
            <el-alert
              v-if="!manufactures.length && !manufactureLoading"
              :title="$t('page.servicePosition.messages.noManufacture')"
              type="warning"
              :closable="false"
              show-icon
            ></el-alert>
          </div>
        </el-row>
      </el-col>
    </el-row>

    <!-- Service List -->
    <el-row v-if="services.length && !selectedService" :gutter="20">
      <el-col :span="24">
        <div class="service-list">
          <el-row>
            <ServiceItem
              v-for="(service, key) in services"
              :key="key"
              :service="service"
              @selectService="selectService"
            />
          </el-row>
        </div>
      </el-col>
    </el-row>

    <!-- Display selected service details -->
    <el-row v-if="selectedService" :gutter="20">
      <ServiceItem
        v-loading="positionLoading"
        :service="selectedService"
        mode="display"
        @deselectService="deselectService"
      >
        <template v-slot:position-form>
          <!-- Service position form -->
          <hr />
          <el-row v-if="selectedService" :gutter="20">
            <ValidationObserver ref="observer" tag="form">
              <el-form ref="servicePositionForm" :model="form">
                <el-col :span="24" class="mt-05">
                  <h2>
                    {{ $t('page.servicePosition.sectionTitle.serviceDetails') }}
                  </h2>
                </el-col>

                <el-col :span="12">
                  <ValidatedInput
                    v-model="form.serialNumber"
                    label="serialNumber"
                    :placeholder="$t('form.service.placeholder.serialNumber')"
                  />
                </el-col>

                <el-col v-if="serviceIsFox" :span="12">
                  <ValidatedInput
                    v-model="form.idCode"
                    label="idCode"
                    rules="idCode"
                    :placeholder="$t('form.service.placeholder.idCode')"
                  />
                </el-col>

                <el-col :span="12">
                  <ValidatedInput
                    v-model="form.customerReference"
                    label="customerReference"
                    :placeholder="
                      $t('form.service.placeholder.customerReference')
                    "
                  />
                </el-col>

                <el-col :span="24">
                  <ValidatedInput
                    v-model="form.name"
                    type="textarea"
                    label="description"
                    :placeholder="$t('form.service.placeholder.description')"
                  />
                </el-col>

                <el-col :span="24">
                  <el-form-item class="flex-row-center">
                    <el-switch v-model="form.warranty"></el-switch>
                    <span class="ml-1 display-ib">{{
                      $t('form.service.placeholder.warranty')
                    }}</span>
                  </el-form-item>
                  <el-alert
                    v-if="form.warranty"
                    :title="$t('page.servicePosition.messages.warranty')"
                    type="warning"
                    :closable="false"
                    show-icon
                  ></el-alert>
                </el-col>

                <el-col
                  v-if="selectedService.availableOptions.length"
                  :span="24"
                  class="mt-05"
                >
                  <h2>
                    {{
                      $t('page.servicePosition.sectionTitle.serviceAdditions')
                    }}
                  </h2>
                </el-col>

                <el-col :span="24">
                  <el-checkbox-group v-model="form.options">
                    <el-checkbox
                      v-for="(option, key) in selectedService.availableOptions"
                      :key="key"
                      :label="option.id"
                      >{{ option.displayName() }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-col>

                <el-col :span="24" class="h-text-right">
                  <hr />
                  <el-form-item class="mb-0">
                    <el-button
                      type="primary"
                      size="small"
                      @click="submitForm"
                      >{{ $t('form.control.save') }}</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-form>
            </ValidationObserver>
          </el-row>
        </template>
      </ServiceItem>
    </el-row>
    <hr />
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { includes } from 'lodash';
import ServiceItem from '@/components/ServiceListItem';
import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';

export default {
  components: {
    ServiceItem,
    ValidationObserver,
    ValidatedInput,
  },
  props: {
    order: {
      type: Object,
      require: true,
      default: null,
    },
  },
  data() {
    return {
      manufactureLoading: true,
      serviceLoading: true,
      positionLoading: false,
      selectedServiceType: null,
      selectedManufacture: null,
      selectedService: null,
      form: {
        serialNumber: null,
        idCode: null,
        customerReference: null,
        name: null,
        warranty: false,
        order: null,
        options: [],
      },
    };
  },
  computed: {
    ...mapState('ServiceOrder', ['serviceTypes', 'manufactures', 'services']),
    serviceIsFox() {
      return (
        this.selectedService !== null &&
        this.selectedManufacture !== null &&
        includes(this.selectedManufacture.name, 'Fox')
      );
    },
  },
  mounted() {
    this.unsetServices();
  },
  methods: {
    ...mapActions('ServiceOrder', [
      'getManufactures',
      'getServices',
      'createServicePosition',
    ]),
    ...mapMutations('ServiceOrder', ['unsetServices']),
    /**
     * Load manufactures based on selected service type.
     * Unset the selectes manufacture (if not it would lead to preselected manufactures on changing type)
     * Unset the selected service
     */
    async handleManufactureLoading(type) {
      this.manufactureLoading = true;
      this.selectedService = null;
      await this.getManufactures(type);
      this.selectedManufacture = null;
      this.manufactureLoading = false;
    },
    /**
     * Load services based on selected service type and manufacture.
     * Unset the selected service
     */
    async handleServiceLoading(manufacture) {
      this.serviceLoading = true;
      await this.getServices({
        type: this.selectedServiceType,
        manufacture: manufacture.id,
      });
      this.selectedService = null;
      this.serviceLoading = false;
    },
    /**
     * Set the service that was selected and emitted
     * trough the child component.
     */
    selectService(service) {
      this.selectedService = service;
    },
    /**
     * Deselect the selected service in order to change
     * to a different service
     */
    deselectService() {
      this.selectedService = null;
    },
    /**
     * Submit the service position form to save a new service position
     */
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.positionLoading = true;
        await this.savePosition();
        this.$emit('toggleForm');
      } else {
        this.$message({
          showClose: true,
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
    },
    /**
     * Saving the new service position
     */
    async savePosition() {
      // Pass the order and service id in to the form object
      // as this is neede to create the positiion
      this.form.order = this.order.id;
      this.form.service = this.selectedService.id;
      try {
        await this.createServicePosition(this.form);
        this.$message({
          showClose: true,
          message: this.$t('page.servicePosition.messages.creationSuccess'),
          type: 'success',
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t('page.servicePosition.messages.creationError'),
          type: 'error',
        });
      }
    },
  },
};
</script>
<style lang="scss">
.position-form .el-radio__input .el-radio__inner {
  display: none !important;
}
</style>
<style lang="scss" scoped>
@import '../assets/scss/settings';

h2 {
  margin-bottom: 1rem;
}
.manufactures-list,
.service-list {
  min-height: 5rem;
}
</style>
