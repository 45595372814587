<template>
  <div class="property">
    <div class="label">
      {{ $t('page.agent.servicePositionDetail.options') }}
    </div>
    <div class="value">
      <div
        v-for="(o, key) in options"
        :key="key"
        class="flex-row-center justify-between"
      >
        <div>{{ o.name }}</div>
        <div>+ CHF {{ o.price }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss">
@import '../../../assets/scss/settings';

.property {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  .label {
    color: $--color-gray;
    margin-bottom: 0.5rem;
  }
  .value {
    font-size: 1.15rem;
  }
}
</style>
