<template>
  <div class="create-account">
    <div class="create-account__form">
      <ValidationObserver ref="observer" tag="form">
        <el-form ref="loginForm" v-loading="isLoading" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <ValidatedSelect
                v-model="form.customer.type"
                label="customerType"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.type') + ' *'
                "
              >
                <el-option
                  v-for="type in selectCustomerTypes"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>

          <el-row v-if="form.customer.type" :gutter="20" class="mt-1">
            <el-col :span="24">
              <h2 class="mb-1 form-section">
                {{ $t('form.createAccount.sectionTitle.customer.accountInfo') }}
              </h2>
            </el-col>

            <el-col :span="12">
              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.companyName"
                label="companyName"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.companyName') +
                  ' *'
                "
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.companyAddition"
                label="companyAddition"
                rules=""
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.companyAddition'
                  ) + ' *'
                "
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.swissUid"
                label="swissUid"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.swissUid') + ' *'
                "
              />

              <ValidatedSelect
                v-model="form.customer.salutation"
                :label="false"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.salutation') +
                  ' *'
                "
              >
                <el-option
                  v-for="salutation in selectSalutations"
                  :key="salutation.value"
                  :label="salutation.label"
                  :value="salutation.value"
                ></el-option>
              </ValidatedSelect>

              <ValidatedInput
                v-model="form.customer.firstName"
                label="firstName"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.firstName') + ' *'
                "
              />

              <ValidatedInput
                v-model="form.customer.lastName"
                label="lastName"
                rules="required"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.lastName') + ' *'
                "
              />
            </el-col>
            <el-col :span="12">
              <ValidatedInput
                v-model="form.customer.phone"
                label="phone"
                rules="required|phone:shippingCountry"
                :placeholder="
                  $t('form.createAccount.placeholder.customer.phone') + ' *'
                "
              />

              <ValidatedInput
                v-model="form.email"
                label="email"
                rules="required|email"
                :placeholder="$t('form.createAccount.placeholder.email') + ' *'"
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.web"
                label="web"
                :placeholder="$t('form.createAccount.placeholder.customer.web')"
              />

              <ValidatedInput
                v-if="!backoffice"
                v-model="form.password"
                type="password"
                label="password"
                :rules="!backoffice ? 'required' : ''"
                :placeholder="
                  $t('form.createAccount.placeholder.password') + ' *'
                "
                vid="password"
              />

              <ValidatedInput
                v-if="!backoffice"
                v-model="form.password2"
                type="password"
                label="password2"
                :rules="!backoffice ? 'required|confirmed:password' : ''"
                :placeholder="
                  $t('form.createAccount.placeholder.password2') + ' *'
                "
              />
            </el-col>
          </el-row>

          <el-row :gutter="20" class="mt-1">
            <el-col :span="12">
              <h2 class="mb-1 form-section">
                {{
                  $t('form.createAccount.sectionTitle.customer.shippingAddress')
                }}
              </h2>

              <ValidatedInput
                v-model="form.customer.shippingAddress.street"
                label="shippingStreet"
                rules="required"
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.shippingAddress.street'
                  ) + ' *'
                "
              />

              <ValidatedInput
                v-model="form.customer.shippingAddress.addition"
                label="shippingAddition"
                rules=""
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.shippingAddress.addition'
                  )
                "
              />

              <ValidatedSelect
                v-model="form.customer.shippingAddress.country"
                vid="shippingCountry"
                :label="false"
                rules="required"
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.shippingAddress.country'
                  ) + ' *'
                "
              >
                <el-option
                  v-for="type in selectCountries"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>

              <el-row :gutter="20">
                <el-col :span="8">
                  <ValidatedInput
                    v-model="form.customer.shippingAddress.zipCode"
                    label="shippingZipCode"
                    rules="required|zip"
                    :placeholder="
                      $t(
                        'form.createAccount.placeholder.customer.shippingAddress.zipCode'
                      ) + ' *'
                    "
                    :disabled="!form.customer.shippingAddress.country"
                  />
                </el-col>
                <el-col :span="16">
                  <ValidatedSelect
                    v-model="form.customer.shippingAddress.city"
                    :loading="isLoadingShipTown"
                    :label="false"
                    rules="required"
                    :placeholder="
                      $t(
                        'form.createAccount.placeholder.customer.shippingAddress.city'
                      ) + ' *'
                    "
                    :disabled="!selectShipTowns.length"
                  >
                    <el-option
                      v-for="(town, key) in selectShipTowns"
                      :key="key"
                      :label="town['place name']"
                      :value="town['place name']"
                    ></el-option>
                  </ValidatedSelect>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <h2 class="mb-1 form-section">
                {{
                  $t('form.createAccount.sectionTitle.customer.invoiceAddress')
                }}
              </h2>

              <ValidatedInput
                v-model="form.customer.invoiceAddress.street"
                label="invoiceStreet"
                :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.invoiceAddress.street'
                  ) + ' *'
                "
                :disabled="form.shipAddressIsInvAddress"
              />

              <ValidatedInput
                v-model="form.customer.invoiceAddress.addition"
                label="invoiceAddition"
                rules=""
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.invoiceAddress.addition'
                  )
                "
                :disabled="form.shipAddressIsInvAddress"
              />

              <ValidatedSelect
                v-model="form.customer.invoiceAddress.country"
                :label="false"
                :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                :placeholder="
                  $t(
                    'form.createAccount.placeholder.customer.invoiceAddress.country'
                  ) + ' *'
                "
                :disabled="form.shipAddressIsInvAddress"
              >
                <el-option
                  v-for="type in selectCountries"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>

              <el-row :gutter="20">
                <el-col :span="8">
                  <ValidatedInput
                    v-model="form.customer.invoiceAddress.zipCode"
                    label="invoiceZipCode"
                    :rules="!form.shipAddressIsInvAddress ? 'required|zip' : ''"
                    :placeholder="
                      $t(
                        'form.createAccount.placeholder.customer.invoiceAddress.zipCode'
                      ) + ' *'
                    "
                    :disabled="
                      form.shipAddressIsInvAddress ||
                      !form.customer.invoiceAddress.country
                    "
                  />
                </el-col>
                <el-col :span="16">
                  <ValidatedSelect
                    v-model="form.customer.invoiceAddress.city"
                    :loading="isLoadingInvTown"
                    :label="false"
                    :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                    :placeholder="
                      $t(
                        'form.createAccount.placeholder.customer.invoiceAddress.city'
                      ) + ' *'
                    "
                    :disabled="
                      !selectInvTowns.length || form.shipAddressIsInvAddress
                    "
                  >
                    <el-option
                      v-for="(town, key) in selectInvTowns"
                      :key="key"
                      :label="town['place name']"
                      :value="town['place name']"
                    ></el-option>
                  </ValidatedSelect>
                </el-col>
              </el-row>
            </el-col>

            <el-col>
              <el-form-item class="flex-row-center">
                <el-switch v-model="form.shipAddressIsInvAddress"></el-switch>
                <span class="ml-1 display-ib">{{
                  $t('form.createAccount.placeholder.shipAddressIsInvAddress')
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <hr />
          <el-row>
            <el-col>
              <div class="flex-row-center justify-between">
                <el-form-item>
                  <router-link
                    v-if="backoffice"
                    :to="{ name: 'backofficeContact' }"
                    tag="el-button"
                    >{{ $t('form.control.cancel') }}</router-link
                  >
                  <router-link v-else :to="{ name: 'login' }" tag="el-button">{{
                    $t('page.createAccount.button.backToLogin')
                  }}</router-link>
                </el-form-item>

                <el-form-item>
                  <el-button
                    v-if="backoffice"
                    type="primary"
                    class="el-button--block"
                    @click="submitForm"
                    >{{ $t('form.control.save') }}</el-button
                  >
                  <el-button
                    v-else
                    type="primary"
                    class="el-button--block"
                    @click="submitForm"
                    >{{
                      $t('page.createAccount.button.createAccount')
                    }}</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';

import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';
import ValidatedSelect from '@/components/inputs/ValidatedSelect';

export default {
  components: {
    ValidationObserver,
    ValidatedInput,
    ValidatedSelect,
  },
  props: {
    backoffice: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingShipTown: false,
      isLoadingInvTown: false,
      selectCustomerTypes: [
        { label: this.$t('form.option.privateCustomer'), value: 'private' },
        { label: this.$t('form.option.businessCustomer'), value: 'business' },
      ],
      selectCountries: [
        { label: this.$t('form.option.switzerland'), value: 'CH' },
        { label: this.$t('form.option.liechtenstein'), value: 'LI' },
      ],
      selectSalutations: [
        { label: this.$t('form.option.mr'), value: 'mr' },
        { label: this.$t('form.option.mrs'), value: 'mrs' },
      ],
      selectShipTowns: [],
      selectInvTowns: [],
      form: {
        email: null,
        password: null,
        password2: null,
        shipAddressIsInvAddress: true,
        customer: {
          firstName: null,
          lastName: null,
          companyName: null,
          type: null,
          salutation: null,
          companyAddition: null,
          phone: null,
          web: null,
          swissUid: null,
          language: 'de', // this.$i18n.locale,
          shippingAddress: {
            street: null,
            addition: null,
            country: null,
            zipCode: null,
            city: null,
          },
          invoiceAddress: {
            street: null,
            addition: null,
            country: null,
            zipCode: null,
            city: null,
          },
        },
      },
    };
  },
  computed: {
    successNotification() {
      return this.backoffice
        ? this.$t('page.backoffice.createCustomer.message.registerSuccess')
        : this.$t('page.createAccount.message.registerSuccess');
    },
    errorNotification() {
      return this.backoffice
        ? this.$t('page.backoffice.createCustomer.message.registerError')
        : this.$t('page.createAccount.message.registerError');
    },
    redirecRoute() {
      return this.backoffice ? 'backofficeContact' : 'root';
    },
  },
  watch: {
    'form.customer.shippingAddress.country': function () {
      this.form.customer.shippingAddress.zipCode = '';
      this.selectShipTowns = [];
      this.form.customer.shippingAddress.city = '';
    },
    'form.customer.invoiceAddress.country': function () {
      this.form.customer.invoiceAddress.zipCode = '';
      this.selectInvTowns = [];
      this.form.customer.invoiceAddress.city = '';
    },
    'form.customer.shippingAddress.zipCode': function (zipCode) {
      if (zipCode.length === 4) {
        this.clearTown('form.customer.shippingAddress.city', 'Ship');
        this.zipLookup(zipCode, 'Ship');
      }
    },
    'form.customer.invoiceAddress.zipCode': function (zipCode) {
      if (zipCode.length === 4) {
        this.clearTown('form.customer.invoiceAddress.city', 'Inv');
        this.zipLookup(zipCode, 'Inv');
      }
    },
    'form.customer.type': function () {
      if (this.form.customer.type === 'private') {
        this.clearBussinessFields();
      }
      if (this.form.customer.type === 'business') {
        this.clearPrivateFields();
      }
    },
    'form.shipAddressIsInvAddress': function () {
      if (this.form.shipAddressIsInvAddress) {
        this.clearInvAddressFields();
      }
    },
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        this.createAccount();
      } else {
        this.$message({
          showClose: true,
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
    },
    async createAccount() {
      try {
        await this.$store.dispatch('User/create', this.form);
        this.$message({
          showClose: true,
          message: this.successNotification,
          type: 'success',
          duration: 15000,
          dangerouslyUseHTMLString: true,
        });
        this.$router.push({ name: this.redirecRoute });
      } catch (e) {
        const error = _.get(e, 'response.data');

        if (error.email) {
          this.$message({
            showClose: true,
            message: this.$t(
              'page.createAccount.message.registerErrorEmailExists'
            ),
            type: 'error',
          });
        } else {
          this.$message({
            showClose: true,
            message: this.errorNotification,
            type: 'error',
          });
        }

        this.isLoading = false;
      }
    },
    changeCountry(addressType) {
      switch (addressType) {
        case 'Inv':
          this.form.customer.invoiceAddress.zipCode = '';
          this.selectInvTowns = [];
          this.form.customer.invoiceAddress.city = null;
          break;
        default:
          this.form.customer.shippingAddress.zipCode = '';
          this.selectShipTowns = [];
          this.form.customer.shippingAddress.city = null;
          break;
      }
    },
    clearTown(addressCity, townsType) {
      this[`select${townsType}Towns`] = [];
      this[addressCity] = null;
    },
    zipLookup(zipCode, townsType) {
      let country = null;
      switch (townsType) {
        case 'Inv':
          country = this.form.customer.invoiceAddress.country;
          break;
        default:
          country = this.form.customer.shippingAddress.country;
          break;
      }
      this[`isLoading${townsType}Town`] = true;
      axios
        .get(`https://api.zippopotam.us/${country}/${zipCode}`)
        .then((response) => {
          this[`select${townsType}Towns`] = response.data.places;
          this.preselectCity(this[`select${townsType}Towns`], townsType);
          this[`isLoading${townsType}Town`] = false;
        })
        .catch((error) => {
          this[`isLoading${townsType}Town`] = false;
          this.$message({
            showClose: true,
            message: this.$t('page.createAccount.message.wrongZip'),
            type: 'error',
          });
        });
    },
    preselectCity(city, addressType) {
      if (city.length > 0) {
        switch (addressType) {
          case 'Inv':
            this.form.customer.invoiceAddress.city = city[0]['place name'];
            break;
          default:
            this.form.customer.shippingAddress.city = city[0]['place name'];
            break;
        }
      }
    },
    clearBussinessFields() {
      this.form.customer.companyName = null;
      this.form.customer.companyAddition = null;
      this.form.customer.swissUid = null;
      this.form.customer.web = null;
    },
    clearPrivateFields() {
      this.form.customer.salutation = null;
    },
    clearInvAddressFields() {
      this.form.customer.invoiceAddress.street = null;
      this.form.customer.invoiceAddress.addition = null;
      this.form.customer.invoiceAddress.country = null;
      this.form.customer.invoiceAddress.zipCode = null;
      this.form.customer.invoiceAddress.city = null;
    },
  },
};
</script>
