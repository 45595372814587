import { unpack } from './helpers';
import http from './http';
import User from '../models/User.model';

export const login = async (credentials) => {
  const response = await unpack(http.post('/token/', credentials));
  return new User(response);
};

export const refreshToken = async (token) => {
  return unpack(http.post('token/refresh/', { refresh: token }));
};

export const create = async (formData) => {
  return unpack(http.post('/user/', formData));
};

export const edit = async (formData) => {
  return unpack(http.put(`/user/${formData.id}/`, formData));
};

export const loadUser = async (id) => {
  let response = await unpack(http.get(`/user/${id}/`));
  return new User(response);
};
