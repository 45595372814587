<!-- Source: https://codesandbox.io/s/veevalidate-components-element-ui-veevalidate-30-hk3un?fontsize=14 -->
<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :name="$attrs.name"
    :rules="rules"
  >
    <el-form-item
      :label="$attrs.label ? $attrs.placeholder : ''"
      :error="errors[0]"
    >
      <el-select
        v-model="innerValue"
        v-loading="$attrs.loading"
        :disabled="$attrs.disabled"
        v-bind="$attrs"
      >
        <slot />
      </el-select>
    </el-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
