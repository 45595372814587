<template>
  <el-row>
    <el-col :span="24">
      <div v-if="position.service" class="position">
        <div class="position__head">
          <div class="position__details flex-row-center">
            <span
              ><i class="el-icon-tickets"></i> {{ position.service.name }}</span
            >

            <span v-if="position.serialNumber" class="position__icon">
              <img src="../assets/images/barcode.svg" />
              {{ position.serialNumber }}
            </span>

            <span
              v-if="position.idCode"
              class="position__icon position__icon--end"
            >
              <img src="../assets/images/id_code.svg" />
              {{ position.idCode }}
            </span>

            <span v-if="hasOptions"
              ><i class="el-icon-s-operation"></i>
              {{ position.optionsList() }}</span
            >
            <span
              ><i class="el-icon-money"></i> CHF
              {{ position.price.toFixed(2) }}</span
            >
          </div>
          <div v-if="order.isEditable()" class="position__controls">
            <el-button
              size="mini"
              type="info"
              icon="el-icon-edit"
              circle
              @click="toggleEditForm"
            ></el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              circle
              @click="confirm('warning', position.id)"
            ></el-button>
          </div>
        </div>
        <div class="position__form">
          <!-- Form to edit a position -->
          <ServicePositionEditForm
            v-if="showEditForm"
            :position="position"
            @toggleEditForm="toggleEditForm"
          />
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
import { mapActions } from 'vuex';
import { isEmpty } from 'lodash';
import ConfirmAction from '@/mixins/ConfirmAction';
import ServicePositionEditForm from '@/components/ServicePositionEditForm';
export default {
  components: {
    ServicePositionEditForm,
  },
  mixins: [ConfirmAction],
  props: {
    position: {
      type: Object,
      required: true,
      default: null,
    },
    order: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      showEditForm: false,
    };
  },
  computed: {
    hasOptions() {
      return !isEmpty(this.position.options);
    },
  },
  methods: {
    ...mapActions('ServiceOrder', ['deleteServicePosition']),
    async confirmAction(obj) {
      await this.deleteServicePosition(obj);
    },
    toggleEditForm() {
      this.showEditForm = !this.showEditForm;
    },
    cancelEditing() {
      this.positionToEdit = {};
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/settings';
.position {
  background-color: $--color-light-gray;
  border-left: 0.4rem solid $--color-primary;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
}
.position__head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.position__details {
  color: $--color-gray;
  span {
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .position__icon {
    display: inline-flex;
    align-items: center;
    &--end {
      align-items: end;
    }
    img {
      height: 16px;
      margin-right: 5px;
    }
  }
}
.position__controls {
  text-align: right;
  .control:not(last-of-type) {
    margin-right: 0.15rem;
  }
}
</style>
