<template>
  <el-container class="agent-layout">
    <el-aside width="10%">
      <NavBar />
      <Avatar />
    </el-aside>

    <el-container v-loading="isLoading" class="agent-content">
      <el-header v-if="true">
        <el-row>
          <!-- BREADCRUMBS -->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'agentIndex' }"
              ><icon-base width="20" height="20" icon-color="#606266"
                ><IconCompass /></icon-base
            ></el-breadcrumb-item>
            <el-breadcrumb-item
              v-for="(crumb, key) in breadcrumbs"
              :key="key"
              :to="crumb.to"
              >{{ crumb.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
          <!-- !BREADCRUMBS -->
        </el-row>
      </el-header>
      <el-main class="page-main container">
        <el-row
          type="flex"
          justify="space-between"
          class="mb-2 title-row title-row__border-bottom"
        >
          <div class="flex-row-center justify-between">
            <slot name="page-navigation"></slot>
            <h1 class="page-title">{{ pageTitle }}</h1>
          </div>
          <slot name="page-controls"></slot>
        </el-row>
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import NavBar from '@/components/agent/NavBar';
import Avatar from '@/components/agent/Avatar';
import IconCompass from '@/components/icons/IconCompass';

import { mapGetters } from 'vuex';

export default {
  name: 'AgentPage',
  components: {
    NavBar,
    Avatar,
    IconCompass,
  },
  props: {
    pageTitle: {
      type: String,
      required: false,
      default: '',
    },
    breadcrumbs: {
      type: Array,
      require: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('Loading', ['loading']),
    isLoading() {
      return this.loading;
    },
  },
};
</script>

<style lang="scss">
.el-header {
  .el-breadcrumb {
    display: flex;
    align-items: center;
    &__item {
      display: flex;
      align-items: center;
    }
    &__inner {
      font-size: 1.2rem;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '../assets/scss/settings';
.agent-layout {
  height: 100vh;
  .agent-content {
    background-color: #efefef;
  }
  .el-aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    background-color: $--color-primary;
  }
  .el-header {
    display: flex;
    align-items: center;
    padding: 0 2rem;
    border-bottom: 1px solid lighten($--color-gray, 50%);
    background-color: $--color-light-gray;
  }
  .el-main {
    height: 100%;
    padding: 2rem 2rem 0 !important;
  }
}
</style>
