import Customer from '@/models/Customer.model';
class User {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.email = payload && payload.email ? payload.email : null;
    this.role = payload.role || null;
    this.token = payload.access || null;
    this.refreshToken = payload.refresh || null;
    this.customer = payload.customer ? new Customer(payload.customer) : null;
  }

  deserializeFromLocalStorage(payload) {
    if (!payload) return;

    this.id = payload.id || null;
    this.email = payload.email || null;
    this.role = payload.role || null;
    this.token = payload.token || null;
    this.refreshToken = payload.refreshToken || null;
    this.customer = payload.customer ? new Customer(payload.customer) : null;
    return this;
  }
}

export default User;
