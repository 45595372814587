<template>
  <el-table
    v-loading="loading"
    :data="orders"
    :show-header="false"
    :empty-text="$t('table.orders.noData')"
    row-class-name="clickable-row"
    @row-click="goTo"
  >
    <el-table-column prop="id" width="75" sortable> </el-table-column>

    <el-table-column sortable>
      <template slot-scope="scope">
        {{ scope.row.customer.fullName() }}
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="60">
      <template slot-scope="scope">
        <i class="el-icon-document-copy"></i> {{ scope.row.posCount() }}
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="150">
      <template slot-scope="scope">
        <i class="el-icon-timer" :class="{ express: scope.row.isExpress }"></i>
        {{ scope.row.serviceSpeed() }}
      </template>
    </el-table-column>

    <el-table-column fixed="right" width="250">
      <template slot-scope="scope">
        <el-tag :type="scope.row.tagColor()" size="small" disable-transitions>
          {{ scope.row.statusDisplay }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column fixed="right" align="right" width="30">
      <i class="el-icon-arrow-right"></i>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    orders: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    goTo(row) {
      this.$router.push({
        name: 'backofficeOrderDetail',
        params: { id: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/settings';
.express {
  color: $--color-danger;
}
</style>
<style lang="scss">
tr.clickable-row {
  cursor: pointer;
}
</style>
