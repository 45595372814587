import Vue from 'vue';
import Router from 'vue-router';

import routes from './routes';
import store from '../store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const loggedIn = store.getters['User/isLoggedIn'];
  const role = store.getters['User/getUserRole'];

  if (authorize) {
    // If the user is not logged -> Send to login page
    if (!loggedIn) {
      return next({ name: 'login' });
    }

    // If the user is not allowed to see this route -> Send to index page
    if (authorize.length && !authorize.includes(role)) {
      return next({ name: 'root' });
    }
  }

  next();
});

export default router;
