/**
 * Mixin for pagination and serach functionallity
 * Needs the following functions and data props in the parent component:
 *
 * String:The namespace of the store.
 * Function: The search function to be called in the store.
 */
export default {
  computed: {
    searchTerm: {
      get() {
        return this.$store.state[this.storeNameSpace].searchTerm;
      },
      set(value) {
        this.handleSearchTerm(value);
      },
    },
  },
  methods: {
    /**
     * Handle the update of the current page.
     * Display loader during loading of data
     */
    async handleUpdateCurrentPage(page) {
      this.isLoading = true;
      await this.updateCurrentPage(page);
      this.isLoading = false;
    },
    /**
     * Handle search field input and triger
     * loading new data from the API
     */
    async handleSearchTerm(term) {
      this.isLoading = true;
      await this[this.searchFunctionName](term);
      this.isLoading = false;
    },
  },
};
