import { isAdmin, isStaff } from '@/auth/helper';

import generalRoutes from '@/router/general';
import customerRoutes from '@/router/customer';
import backofficeRoutes from '@/router/backoffice';
import agentRoutes from '@/router/agent';

export default [
  {
    path: '/',
    name: 'root',
    redirect: () => {
      if (isStaff() || isAdmin()) {
        return { name: 'backofficeDashboard' };
      } else {
        return { name: 'customerDashboard' };
      }
    },
  },
  ...generalRoutes,
  ...customerRoutes,
  ...backofficeRoutes,
  ...agentRoutes,
];
