/*
  Configuration values are loaded from two locations:
  1. Inside Docker image: /app/config/config.js is generated on container start based on configuration
     values provided by environment variables (see docker/entrypoint.sh). This file sets the window.__env variable.
     It is referenced in index.html and gets loaded when the application starts in the browser.
  2. Development environment: The variables are configured in config/dev.env.js and provided as process.env.X variables
     at runtime.
 */
export default {
  API_URL:
    (window.__env && window.__env.API_URL) || process.env.VUE_APP_API_URL,
};
