import { CUSTOMER, STAFF, ADMIN } from '@/auth/roles';

import Login from '@/views/Login';
import Logout from '@/components/customer/auth/Logout';
import ForgotPassword from '@/views/ForgotPassword';
import UserAccount from '@/views/UserAccount';
import CreateAccount from '@/views/CreateAccount';
import EditAccount from '@/views/EditAccount';

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {},
  },
  {
    path: '/account',
    name: 'userAccount',
    component: UserAccount,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
    },
  },
  {
    path: '/account/create',
    name: 'createAccount',
    component: CreateAccount,
    meta: {},
  },
  {
    path: '/account/edit',
    name: 'editAccount',
    component: EditAccount,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
    },
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    meta: {},
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
    },
  },
];
