<template>
  <div v-if="!isLoading" class="create-service-order">
    <div class="create-service-order__form">
      <ValidationObserver ref="observer" tag="form">
        <el-form ref="loginForm" v-loading="isLoading" :model="form">
          <el-row v-if="!inDisplayMode" :gutter="20">
            <el-col :span="9">
              <ValidatedSelect
                v-model="form.shippingMethod"
                :label="true"
                value-key="id"
                :placeholder="
                  $t('form.serviceOrder.placeholder.shippingMethods') + ' *'
                "
              >
                <el-option
                  v-for="(method, key) in shippingMethods"
                  :key="key"
                  :label="method.name"
                  :value="method"
                ></el-option>
              </ValidatedSelect>
            </el-col>
            <el-col :span="9">
              <ValidatedSelect
                v-model="form.paymentMethod"
                :label="true"
                value-key="id"
                :placeholder="
                  $t('form.serviceOrder.placeholder.paymentMethods') + ' *'
                "
              >
                <el-option
                  v-for="(method, key) in paymentMethods"
                  :key="key"
                  :label="method.name"
                  :value="method"
                ></el-option>
              </ValidatedSelect>
            </el-col>
            <el-col :span="6">
              <ValidatedSelect
                v-model="form.isExpress"
                :label="true"
                :placeholder="
                  $t('form.serviceOrder.placeholder.isExpress') + ' *'
                "
              >
                <el-option
                  v-for="(speed, key) in form.serviceSpeeds"
                  :key="key"
                  :label="speed.title"
                  :value="speed.value"
                ></el-option>
              </ValidatedSelect>
            </el-col>
          </el-row>

          <el-row v-else class="mb-05 flex-row-center">
            <el-col :span="6">
              <i class="el-icon-box"></i> {{ order.shippingMethod.name }}
            </el-col>
            <el-col :span="6">
              <i class="el-icon-money"></i> {{ order.paymentMethod.name }}
            </el-col>
            <el-col :span="6">
              <i class="el-icon-timer"></i> {{ order.serviceSpeed() }}
            </el-col>
            <el-col v-if="order.isEditable()" :span="6" class="h-text-right">
              <el-button
                v-if="inDisplayMode"
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="turnOnEditMode"
                >{{
                  $t('form.serviceOrder.controls.editServiceOrder')
                }}</el-button
              >
            </el-col>
            <el-col v-else :span="6">
              <i class="el-icon-more-outline"></i> {{ order.statusDisplay }}
            </el-col>
          </el-row>

          <el-row v-if="!inDisplayMode" :gutter="20">
            <el-col v-if="form.isExpress" :span="24">
              <el-alert
                class="mb-1"
                :title="$t('form.serviceOrder.message.isExpress')"
                :closable="false"
                type="warning"
                show-icon
              >
              </el-alert>
            </el-col>

            <el-col v-if="showDpdInfo" :span="24">
              <el-alert
                class="mb-1"
                :title="$t('form.serviceOrder.message.dpdInfo')"
                :closable="false"
                type="info"
                show-icon
              >
              </el-alert>
            </el-col>
          </el-row>

          <el-row v-if="!inDisplayMode" :gutter="20">
            <el-col :span="24" class="flex-row-center justify-between">
              <el-button
                v-if="inNewMode"
                type="primary"
                size="small"
                @click="submitForm('create')"
                >{{
                  $t('form.serviceOrder.controls.saveAndCreateService')
                }}</el-button
              >

              <el-button
                v-if="inEditMode"
                type="default"
                size="small"
                @click="turnOnDisplayMode"
                >{{ $t('form.control.cancel') }}</el-button
              >

              <el-button
                v-if="inEditMode"
                type="primary"
                size="small"
                @click="submitForm('update')"
                >{{ $t('form.control.save') }}</el-button
              >
            </el-col>
          </el-row>
          <hr />
        </el-form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { includes } from 'lodash';

import { ValidationObserver } from 'vee-validate';
import ValidatedSelect from '@/components/inputs/ValidatedSelect';
import { serviceSpeeds } from '@/store/modules/helper';

export default {
  components: {
    ValidationObserver,
    ValidatedSelect,
  },
  props: {
    order: {
      type: Object,
      required: false,
      default: null,
    },
    customer: {
      type: Number,
      required: false,
      default: null,
    },
    redirectRoute: {
      type: String,
      required: false,
      default: 'customerOrderEdit',
    },
  },
  data() {
    return {
      isLoading: false,
      componentMode: 'new', // possible modes: new, edit, display
      form: {
        shippingMethod: null,
        paymentMethod: null,
        serviceSpeeds: serviceSpeeds,
        isExpress: 0,
      },
    };
  },
  computed: {
    ...mapState('ServiceOrder', [
      'shippingMethods',
      'paymentMethods',
      'serviceTypes',
      'manufactures',
      'services',
      'currentServiceOrder',
    ]),
    ...mapState('User', ['user']),
    /**
     * Check if the selected shipping method contains DPD and if the customer is a private customer.
     * If so, show a message to warn the customer about parcel shop delivery.
     * Note: This is kinda ugly and should probably be delivered trough the API... lets do this later ;-)
     */
    showDpdInfo() {
      if (this.form.shippingMethod) {
        return (
          includes(this.form.shippingMethod.name, 'DPD') &&
          this.user.customer.type === 'private'
        );
      } else {
        return false;
      }
    },
    /**
     * Helper methods to show/hide elements in this componen
     * Methods for: Edit-, Display- and New-Mode
     */
    inEditMode() {
      return this.componentMode === 'edit';
    },
    inDisplayMode() {
      return this.componentMode === 'display';
    },
    inNewMode() {
      return this.componentMode === 'new';
    },
  },
  mounted() {
    this.isLoading = true;
    this.getServiceOrderOptions().then(() => {
      // Check if a ServiceOrder object was passed in and turn on display mode
      if (this.order !== null) {
        this.componentMode = 'display';
        this.form = Object.assign(this.form, this.order);
      } else {
        this.initSelects();
        if (this.customer) {
          this.form.customer = this.customer;
        }
      }

      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions('ServiceOrder', [
      'getServiceOrderOptions',
      'createServiceOrder',
      'updateServiceOrder',
    ]),
    /**
     * Initialize selects and pre-set first value received from API
     */
    initSelects() {
      this.form.shippingMethod = this.shippingMethods[0] || null;
      this.form.paymentMethod = this.paymentMethods[0] || null;
    },
    /**
     * Submit form and create new service order
     */
    async submitForm(method) {
      const isValid = await this.$refs.observer.validate();
      // Check if form data is valid
      if (isValid) {
        this.isLoading = true;
        try {
          if (method === 'create') {
            // Create new service order and then display success message and foreward to edit page
            this.createServiceOrder(this.form).then(() => {
              this.$message({
                showClose: true,
                message: this.$t('form.serviceOrder.message.creationSuccess'),
                type: 'success',
              });
              this.$router.push({
                name: this.redirectRoute,
                params: { id: this.currentServiceOrder.id },
              });
            });
          } else {
            // Update the curren ServioceOrder object
            this.updateServiceOrder(this.form).then(() => {
              this.$message({
                showClose: true,
                message: this.$t('form.serviceOrder.message.updateSuccess'),
                type: 'success',
              });
              this.turnOnDisplayMode();
            });
          }
        } catch (e) {
          this.$message({
            showClose: true,
            message: this.$t('form.serviceOrder.message.creationError'),
            type: 'error',
          });
        }
      } else {
        this.$message({
          showClose: true,
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
      this.isLoading = false;
    },
    turnOnEditMode() {
      this.componentMode = 'edit';
    },
    turnOnDisplayMode() {
      this.componentMode = 'display';
    },
  },
};
</script>
