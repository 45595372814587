<template>
  <main-page :page-title="pageTitle" :is-loading="isLoading">
    <!-- Navigate back to list view -->
    <template v-slot:page-navigation>
      <div class="page-navigation">
        <router-link :to="{ name: 'backofficeOrderList' }">
          <i class="el-icon-arrow-left"></i>
        </router-link>
      </div>
    </template>

    <!-- Service Order Controls -->
    <template v-slot:page-controls>
      <div>
        <el-button
          v-if="!isLoading && currentServiceOrder.canBeReceived()"
          type="success"
          size="small"
          icon="el-icon-sold-out"
          @click="receive()"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.receive') }}
        </el-button>

        <el-button
          v-if="!isLoading && currentServiceOrder.canBeWorkedOn()"
          type="success"
          size="small"
          icon="el-icon-setting"
          @click="start()"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.start') }}
        </el-button>

        <el-button
          v-if="!isLoading && currentServiceOrder.canBeFinished()"
          type="success"
          size="small"
          icon="el-icon-check"
          @click="finish()"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.finish') }}
        </el-button>

        <el-button
          v-if="isPuckupInvoicing"
          type="success"
          size="small"
          icon="el-icon-money"
          @click="invoice()"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.invoice') }}
        </el-button>

        <el-dropdown v-if="isShippingInvoicing" @command="invoice">
          <el-button type="success" size="small" icon="el-icon-money">
            {{ $t('page.backoffice.serviceOrderDetail.controls.invoice') }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(insurance, key) in insurances"
              :key="key"
              :command="insurance.id"
              >{{ insurance.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>

        <el-button
          v-if="!isLoading && currentServiceOrder.hasInvoice()"
          :loading="pdfLoading"
          type="default"
          size="small"
          icon="el-icon-download"
          @click="downloadInvoice(currentServiceOrder)"
        >
          {{ $t('form.service.controls.downloadInvoice') }}
        </el-button>

        <el-button
          v-if="!isLoading && currentServiceOrder.canBeShipped()"
          type="success"
          size="small"
          icon="el-icon-sell"
          @click="ship()"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.ship') }}
        </el-button>

        <el-button
          v-if="!isLoading && currentServiceOrder.hasTrackingCode()"
          type="default"
          size="small"
          icon="el-icon-discover"
          @click="openTracking(currentServiceOrder)"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.track') }}
        </el-button>

        <el-button
          v-if="!isLoading && currentServiceOrder.canBeDeleted()"
          type="danger"
          size="small"
          icon="el-icon-delete"
          class="ml-05"
          @click="confirm('warning', currentServiceOrder.id)"
        >
          {{ $t('page.backoffice.serviceOrderDetail.controls.delete') }}
        </el-button>
      </div>
    </template>

    <!-- Service Order Form -->
    <ServiceOrderForm
      v-if="!isLoading && currentServiceOrder"
      :order="currentServiceOrder"
    />

    <!-- Service Order and Customer Info -->
    <OrderInfoAddress v-if="currentServiceOrder" :order="currentServiceOrder" />

    <!-- Service Order positions -->
    <ServiceOrderPositions
      v-if="currentServiceOrder"
      :order="currentServiceOrder"
    />

    <!-- Form to create a new position on the service order -->
    <ServicePositionForm
      v-if="!isLoading && showForm"
      :order="currentServiceOrder"
      class="mt-2"
      @toggleForm="toggleCreateForm"
    />

    <!-- Service position form controls -->
    <div class="flex-row-center mt-1">
      <el-button
        v-if="!isLoading && !showForm"
        type="primary"
        size="small"
        icon="el-icon-circle-plus-outline"
        @click="toggleCreateForm"
        >{{ $t('form.service.controls.addService') }}</el-button
      >
      <el-button
        v-if="!isLoading && showForm"
        type="default"
        size="small"
        @click="toggleCreateForm"
        >{{ $t('form.control.cancel') }}</el-button
      >
    </div>

    <!-- Service order packaging
    <div class="flex-row-center mt-1">
      <ServicePackaging :order="currentServiceOrder" />
    </div>
     -->
  </main-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import OrderInfoAddress from '@/components/backoffice/orders/OrderInfoAddress';
import ServiceOrderForm from '@/components/ServiceOrderForm';
import ServiceOrderPositions from '@/components/backoffice/orders/ServiceOrderPositions';
import ServicePositionForm from '@/components/ServicePositionForm';
import ConfirmAction from '@/mixins/ConfirmAction';
import DownloadPdf from '@/mixins/DownloadPdf';
// import ServicePackaging from '@/components/backoffice/ServicePackaging'

export default {
  components: {
    OrderInfoAddress,
    ServiceOrderPositions,
    ServicePositionForm,
    ServiceOrderForm,
    // ServicePackaging
  },
  mixins: [ConfirmAction, DownloadPdf],
  data() {
    return {
      isLoading: true,
      showForm: false,
    };
  },
  computed: {
    ...mapState('ServiceOrder', ['currentServiceOrder', 'insurances']),
    pageTitle() {
      return `${this.$t('page.editServiceOrder.title')}: ${
        this.currentServiceOrder.id
      }`;
    },
    isPuckupInvoicing() {
      return (
        !this.isLoading &&
        this.currentServiceOrder.canBeInvoiced() &&
        this.currentServiceOrder.shippingMethod.isPickup
      );
    },
    isShippingInvoicing() {
      return (
        !this.isLoading &&
        this.currentServiceOrder.canBeInvoiced() &&
        !this.currentServiceOrder.shippingMethod.isPickup
      );
    },
  },
  async mounted() {
    await this.getServiceOrder(this.$route.params.id);
    // If the current order is ready for invoicing and is not for pickup
    // load the insurances for shipping
    if (
      this.currentServiceOrder &&
      !this.currentServiceOrder.shippingMethod.isPickup &&
      this.currentServiceOrder.canBeInvoiced()
    ) {
      await this.getShippingInsurance();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions('ServiceOrder', [
      'getServiceOrder',
      'deleteServiceOrder',
      'receiveOrder',
      'startOrder',
      'finishOrder',
      'invoiceOrder',
      'getShippingInsurance',
      'shipOrder',
    ]),
    async confirmAction(obj) {
      await this.deleteServiceOrder(obj);
      this.$router.push({ name: 'backofficeOrderList' });
    },
    toggleCreateForm() {
      this.showForm = !this.showForm;
    },
    async receive() {
      this.isLoading = true;
      await this.receiveOrder();
      this.isLoading = false;
    },
    async start() {
      this.isLoading = true;
      await this.startOrder();
      this.isLoading = false;
    },
    async finish() {
      this.isLoading = true;
      await this.finishOrder();
      this.isLoading = false;
    },
    async invoice(insurance = null) {
      this.isLoading = true;
      await this.invoiceOrder(insurance);
      this.isLoading = false;
    },
    async ship() {
      this.isLoading = true;
      await this.shipOrder();
      this.isLoading = false;
    },
    openTracking(order) {
      window.open(
        `https://tracking.dpd.de/status/de_DE/parcel/${order.trackingNumber}`,
        '_blank'
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.page-navigation {
  font-size: 2rem;
  margin-right: 1rem;
}
</style>
