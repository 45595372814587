import { unpack } from './helpers';
import http from './http';
import Customer from '@/models/Customer.model';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

// Load a list of customers from the API
// and map each to a Customer object
export const loadCustomers = async (page, term) => {
  let params = {};
  if (page !== 1) params.page = page;
  if (term !== '') params.search = term;

  // cancel previous requests
  cancel && cancel();

  try {
    const response = await unpack(
      http.get('/customer/', {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
    );
    return {
      count: response.count,
      customers: response.results.map((payload) => {
        return new Customer(payload);
      }),
    };
  } catch (err) {}
};

// Load a certan customer from the API
// and map it to a Customer object
export const loadCustomer = async (id) => {
  const response = await unpack(http.get(`/customer/${id}/`));
  return new Customer(response);
};
