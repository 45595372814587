<template>
  <div class="product-container">
    <el-table :data="position.products">
      <el-table-column label="Code" prop="code"> </el-table-column>
      <el-table-column label="Name" prop="name"> </el-table-column>
      <el-table-column label="Description" prop="description"></el-table-column>
      <el-table-column label="QTY" prop="description"></el-table-column>
      <el-table-column label="Price" prop="price"> </el-table-column>
      <el-table-column align="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            plain
            @click="handleDelete(scope.$index, scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.product-container {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 7px;
}
</style>
