<template>
  <nav>
    <div class="nav-item logo">
      <router-link :to="{ name: 'agentIndex' }">
        <img src="@/assets/images/logo_icon.svg" />
      </router-link>
    </div>

    <div class="nav-item">
      <icon-base icon-name="multipleUsers" width="50" height="50"
        ><IconMultipleUsers
      /></icon-base>
    </div>

    <div class="nav-item">
      <icon-base icon-name="orders" width="50" height="50"
        ><IconOrders
      /></icon-base>
    </div>

    <div class="nav-item">
      <router-link :to="{ name: 'agentService' }">
        <icon-base icon-name="wrench" width="50" height="50"
          ><IconWrench
        /></icon-base>
      </router-link>
    </div>

    <div class="nav-item">
      <icon-base icon-name="shipping" width="50" height="50"
        ><IconShipping
      /></icon-base>
    </div>
  </nav>
</template>

<script>
import IconMultipleUsers from '@/components/icons/IconMultipleUsers';
import IconWrench from '@/components/icons/IconWrench';
import IconShipping from '@/components/icons/IconShipping';
import IconOrders from '@/components/icons/IconOrders';

export default {
  components: {
    IconMultipleUsers,
    IconWrench,
    IconShipping,
    IconOrders,
  },
};
</script>

<style lang="scss" scoped>
nav {
  width: 70%;
  margin: 0 auto;

  .nav-item {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .nav-item:first-of-type {
    padding: 0 0 1rem 0;
    border: none;
  }

  .nav-item:last-of-type {
    border: none;
  }

  .nav-item.logo img {
    width: 100%;
    margin: 0 auto 1rem;
  }
}
</style>
