<!-- Source: https://codesandbox.io/s/veevalidate-components-element-ui-veevalidate-30-hk3un?fontsize=14 -->
<template>
  <ValidationProvider
    v-slot="{ errors }"
    :vid="vid"
    :rules="rules"
    :name="$attrs.label"
  >
    <el-form-item :error="errors[0]">
      <el-input
        v-model="innerValue"
        :type="$attrs.type"
        :disabled="$attrs.disabled"
        :placeholder="$attrs.placeholder"
        :label="$attrs.label"
        :pattern="$attrs.pattern"
      ></el-input>
    </el-form-item>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    // must be included in props
    value: {
      type: null,
      default: null,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  },
};
</script>
