<template>
  <main-page :page-title="$t('page.backoffice.createCustomer.title')">
    <CustomerCreateForm :backoffice="true" />
  </main-page>
</template>
<script>
import CustomerCreateForm from '@/components/CustomerCreateForm';

export default {
  components: {
    CustomerCreateForm,
  },
};
</script>
