<template>
  <div id="app">
    <router-view :key="`${$route.fullPath}_${locale}`"></router-view>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'App',
  computed: {
    ...mapState('User', ['language']),
    locale() {
      return this.language || this.$i18n.locale;
    },
  },
  async created() {
    // initiate refresh of token interval if user is logged in. this is usually started
    // after login but if user reloads the page we should start it too
    if (this.$store.getters['User/isLoggedIn']) {
      // wait for response so we know if user's token is still valid before he sees the app
      await this.$store.dispatch('User/tokenRefresh');
    }
  },
  metaInfo: {
    title: 'Connect',
    titleTemplate: '%s | Suspension Center',
    htmlAttrs: {
      lang: 'de-CH',
    },
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content: 'Suspension Center Service Tool',
      },
      { name: 'viewport', content: 'width=device-width,initial-scale=1.0' },
      { name: 'apple-mobile-web-app-capable', content: 'yes' },
      { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    ],
    link: [
      {
        rel: 'stylesheet',
        href:
          'https://fonts.googleapis.com/css?family=Muli:300,400&display=swap',
      },
    ],
    noscript: [
      {
        innerHTML:
          'We are sorry but Suspension Center Connect does not work properly without JavaScript enabled. Please enable it to continue.',
      },
    ],
  },
};
</script>
