import i18n from '@/i18n';

export const serviceTypes = {
  fork: { title: i18n.t('constants.serviceTypes.fork') },
  shock: { title: i18n.t('constants.serviceTypes.shock') },
  seat_post: { title: i18n.t('constants.serviceTypes.seatpost') },
};

export const serviceSpeeds = [
  { title: i18n.t('constants.general.no'), value: 0 },
  { title: i18n.t('constants.general.yes'), value: 1 },
];
