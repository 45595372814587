<template>
  <main-page :page-title="$t('page.backoffice.contact.title')">
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <el-input
          v-model="searchTerm"
          class="mr-1"
          :placeholder="$t('page.backoffice.contact.search')"
          prefix-icon="el-icon-search"
          size="small"
        ></el-input>
        <router-link :to="{ name: 'backofficeCustomerCreate' }">
          <el-button type="primary" size="small">{{
            $t('page.backoffice.contact.new')
          }}</el-button>
        </router-link>
      </div>
    </template>

    <el-row :gutter="20" class="mb-2">
      <el-col :span="24">
        <CustomersTable :customers="customers" :loading="isLoading" />
      </el-col>
    </el-row>

    <el-row :gutter="20" align="center">
      <el-col :span="24">
        <el-pagination
          v-if="!isLoading"
          background
          layout="prev, pager, next"
          :total="customersTotalCount"
          :current-page="currentPage"
          hide-on-single-page
          @current-change="handleUpdateCurrentPage"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </main-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import CustomersTable from '@/components/backoffice/CustomersTable';
import PaginatedSearch from '@/mixins/PaginatedSearch';

export default {
  components: {
    CustomersTable,
  },
  mixins: [PaginatedSearch],
  data() {
    return {
      isLoading: false,
      search: null,
      storeNameSpace: 'Customer',
      searchFunctionName: 'searchCustomer',
    };
  },
  computed: {
    ...mapState('Customer', [
      'customers',
      'customersTotalCount',
      'currentPage',
    ]),
  },
  async mounted() {
    this.isLoading = true;
    await this.getCustomers();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('Customer', [
      'getCustomers',
      'searchCustomer',
      'updateCurrentPage',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.icon {
  height: 25px;
}
</style>
