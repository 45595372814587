/**
 * Unpack the API data and log errors on development
 * @param {Promise} request
 * @return {Promise<*>}
 */
export const unpack = async (request) => {
  try {
    const { data } = await request;
    return data;
  } catch (e) {
    throw e;
  }
};
