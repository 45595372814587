<template>
  <el-row :gutter="20">
    <el-col :span="24" class="flex-row-center justify-between">
      <div>{{ $t('page.servicePosition.pricing.subtotal') }}</div>
      <div>CHF {{ order.positionsPrice.toFixed(2) }}</div>
    </el-col>
    <el-col
      v-if="order.shippingPrice"
      :span="24"
      class="flex-row-center justify-between"
    >
      <div>{{ $t('page.servicePosition.pricing.shipping') }}</div>
      <div>CHF {{ order.shippingPrice.toFixed(2) }}</div>
    </el-col>
    <el-col
      v-if="order.paymentPrice"
      :span="24"
      class="flex-row-center justify-between"
    >
      <div>{{ $t('page.servicePosition.pricing.payment') }}</div>
      <div>CHF {{ order.paymentPrice.toFixed(2) }}</div>
    </el-col>
    <el-col
      v-if="order.expressPrice"
      :span="24"
      class="flex-row-center justify-between"
    >
      <div>{{ $t('page.servicePosition.pricing.express') }}</div>
      <div>CHF {{ order.expressPrice.toFixed(2) }}</div>
    </el-col>
    <el-col :span="24" class="flex-row-center justify-between h-text-bold">
      <div>{{ $t('page.servicePosition.pricing.total') }}</div>
      <div>CHF {{ order.price.toFixed(2) }}</div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
