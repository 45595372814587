import { serviceTypes } from './helper';
import {
  loadManufactures,
  loadServices,
  loadShippingMethods,
  loadPaymentMethods,
  loadInsurances,
  createOrder,
  updateOrder,
  loadServiceOrder,
  loadServiceOrders,
  deleteOrder,
  createPosition,
  deletePosition,
  updatePosition,
  patchServiceOrder,
} from '@/services/ServiceOrder.service';
import store from '../index';

export default {
  namespaced: true,
  state: {
    shippingMethods: [],
    paymentMethods: [],
    serviceTypes: serviceTypes,
    manufactures: [],
    insurances: [],
    services: [],
    currentServiceOrder: {},
    currentPage: 1,
    serviceOrders: [],
    serviceOrdersTotalCount: 0,
    searchTerm: '',
  },
  mutations: {
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setShippingMethods(state, shippingMethods) {
      state.shippingMethods = shippingMethods;
    },
    setPaymentMethods(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
    setManufactures(state, manufactures) {
      state.manufactures = manufactures;
    },
    setServices(state, services) {
      state.services = services;
    },
    unsetServices(state) {
      state.services = [];
    },
    setCurrentServiceOrder(state, currentServiceOrder) {
      state.currentServiceOrder = currentServiceOrder;
    },
    setServiceOrdersList(state, serviceOrders) {
      state.serviceOrders = serviceOrders;
    },
    setServiceOrdersTotalCount(state, count) {
      state.serviceOrdersTotalCount = count;
    },
    setSearchTerm(state, term) {
      state.searchTerm = term;
    },
    setInsurances(state, insurances) {
      state.insurances = insurances;
    },
  },
  actions: {
    async updateCurrentPage({ commit, dispatch }, page) {
      commit('setCurrentPage', page);
      await dispatch('getServiceOrders');
    },
    /**
     * Get all shipping methods from the API
     */
    async getShippingMethods({ commit }) {
      const shippingMethods = await loadShippingMethods();
      commit('setShippingMethods', shippingMethods);
    },
    /**
     * Get all payment methods from the API
     */
    async getPaymentMethods({ commit }) {
      const paymentMethods = await loadPaymentMethods();
      commit('setPaymentMethods', paymentMethods);
    },
    /**
     * Get all manufactures from API and unset all the services
     * as these for sure change with a manufacture change
     */
    async getManufactures({ commit }, type) {
      const manufactures = await loadManufactures(type);
      commit('setManufactures', manufactures);
      commit('unsetServices');
    },
    /**
     * Get all shipping insurance from API
     */
    async getShippingInsurance({ commit }, type) {
      const insurances = await loadInsurances(type);
      commit('setInsurances', insurances);
    },
    /**
     *  Get all services from the API filtered by manufacture
     */
    async getServices({ commit }, { type, manufacture }) {
      const services = await loadServices(type, manufacture);
      commit('setServices', services);
    },
    /**
     * Get a certen service order from the api
     */
    async getServiceOrder({ commit }, id) {
      const serviceOrder = await loadServiceOrder(id);
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Get a list of service order from the api
     */
    async getServiceOrders({ commit, state }) {
      const response = await loadServiceOrders(
        state.currentPage,
        state.searchTerm
      );
      if (response) {
        commit('setServiceOrdersTotalCount', response.count);
        commit('setServiceOrdersList', response.orders);
      }
    },
    /**
     * Create new service order
     */
    async createServiceOrder({ commit }, data) {
      const serviceOrder = await createOrder(data);
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     *  Update an existing ServiceOrder object
     */
    async updateServiceOrder({ commit }, data) {
      const serviceOrder = await updateOrder(data);
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Search the list of ServiceOrders
     * We set the current page back to the first page
     */
    async searchServiceOrder({ commit, dispatch }, term) {
      commit('setSearchTerm', term);
      commit('setCurrentPage', 1);
      await dispatch('getServiceOrders');
    },
    /**
     * Delete an existing ServiceOrder
     */
    async deleteServiceOrder({ dispatch }, order) {
      await deleteOrder(order);
      await dispatch('getServiceOrders');
    },
    /**
     * Get everything that is needed to create a service order
     */
    async getServiceOrderOptions() {
      await store.dispatch('ServiceOrder/getShippingMethods');
      await store.dispatch('ServiceOrder/getPaymentMethods');
    },

    /**
     * Create a new position on a service order
     */
    async createServicePosition({ dispatch, state }, data) {
      await createPosition(data);
      // Reload current service order from API
      dispatch('getServiceOrder', state.currentServiceOrder.id);
    },
    /**
     * Delete a position from a service order
     */
    async deleteServicePosition({ dispatch, state }, position) {
      await deletePosition(position);
      // Reload current service order from API
      dispatch('getServiceOrder', state.currentServiceOrder.id);
    },
    /**
     * Update an existing position
     */
    async updateServicePosition({ dispatch, state }, position) {
      await updatePosition(position);
      // Reload current service order from API
      dispatch('getServiceOrder', state.currentServiceOrder.id);
    },
    /**
     * Hand in an order after compleating the service form as a customer
     */
    async handInServiceOrder({ commit, state }) {
      const serviceOrder = await patchServiceOrder(state.currentServiceOrder, {
        status: 'placed',
      });
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Mark an order as recevied
     */
    async receiveOrder({ commit, state }) {
      const serviceOrder = await patchServiceOrder(state.currentServiceOrder, {
        status: 'received',
      });
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Mark an order as started
     */
    async startOrder({ commit, state }) {
      const serviceOrder = await patchServiceOrder(state.currentServiceOrder, {
        status: 'in progress',
      });
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Mark an order as started
     */
    async finishOrder({ commit, dispatch, state }) {
      const serviceOrder = await patchServiceOrder(state.currentServiceOrder, {
        status: 'ready for invoice',
      });
      commit('setCurrentServiceOrder', serviceOrder);
      dispatch('getShippingInsurance');
    },
    /**
     * Mark an order as shipped
     */
    async shipOrder({ commit, state }) {
      const serviceOrder = await patchServiceOrder(state.currentServiceOrder, {
        status: 'sent',
        trackingNumber: '06085036518522',
      });
      commit('setCurrentServiceOrder', serviceOrder);
    },
    /**
     * Invoice an order
     */
    async invoiceOrder({ commit, state }, insurance) {
      const data = {
        status: 'ready for shipping',
        shippingMethod: state.currentServiceOrder.shippingMethod.id,
      };

      // Add insurance id if an insurance object id is passed
      if (insurance) data.insurance = insurance;

      const serviceOrder = await patchServiceOrder(
        state.currentServiceOrder,
        data
      );
      commit('setCurrentServiceOrder', serviceOrder);
    },
  },
};
