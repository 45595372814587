export default {
  data() {
    return {
      type: 'warning',
    };
  },
  computed: {
    modalConfig() {
      const config = {
        title: this.$t('messageBox.warning'),
        body: this.$t('messageBox.warningMessage'),
        confirm: this.$t('messageBox.delete'),
        cancel: this.$t('messageBox.cancel'),
        class: 'el-button--danger',
        toaster: {
          success: this.$t('messageBox.confirmDeletion'),
          info: this.$t('messageBox.cancelDeletion'),
        },
      };

      if (this.type === 'info') {
        config.title = this.$t('messageBox.info');
        config.body = this.$t('messageBox.infoMessage');
        config.confirm = this.$t('messageBox.confirm');
        config.class = 'el-button--success';
        config.toaster.success = this.$t('messageBox.confirmAction');
        config.toaster.info = this.$t('messageBox.cancelConfirm');
      }

      return config;
    },
  },
  methods: {
    confirm(type, payload) {
      // Set thy type to get correct transaltions and texts
      this.type = type;

      // Open message box
      this.$messageBox
        .confirm(this.modalConfig.body, this.modalConfig.title, {
          confirmButtonText: this.modalConfig.confirm,
          cancelButtonText: this.modalConfig.cancel,
          confirmButtonClass: this.modalConfig.class,
          type: type,
        })
        .then(() => {
          this.$emit('loading', true);
          this.confirmAction(payload).then(() => {
            this.$message({
              type: 'success',
              message: this.modalConfig.toaster.success,
            });
            this.$emit('loading', false);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.modalConfig.toaster.info,
          });
        });
    },
  },
};
