<template>
  <agent-page
    :page-title="pageTitle"
    :breadcrumbs="breadcrumbs"
    class="service"
  >
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <el-button
          v-if="serviceOrder"
          type="success"
          size="small"
          icon="el-icon-check"
          @click="confirm('info', serviceOrder.id)"
          >{{ $t('page.agent.serviceDetail.serviceDone') }}</el-button
        >
      </div>
    </template>
    <section v-if="serviceOrder">
      <ServicePositionList :order="serviceOrder" />
    </section>
  </agent-page>
</template>

<script>
import { mapMutations } from 'vuex';
import ConfirmAction from '@/mixins/ConfirmAction';
import ServicePositionList from '@/components/agent/service/ServicePositionList';
import { loadServiceOrder } from '@/services/ServiceOrder.service';

export default {
  components: {
    ServicePositionList,
  },
  mixins: [ConfirmAction],
  data() {
    return {
      serviceOrder: null,
    };
  },
  computed: {
    pageTitle() {
      return this.serviceOrder
        ? this.$t('page.agent.serviceDetail.title', {
            order: this.serviceOrder.id,
          })
        : '';
    },
    breadcrumbs() {
      if (this.serviceOrder) {
        return [
          { title: this.$t('nav.agent.service'), to: { name: 'agentService' } },
          { title: this.pageTitle, to: null },
        ];
      }
      return [];
    },
  },
  async mounted() {
    this.addLoadingState();
    try {
      this.serviceOrder = await loadServiceOrder(this.$route.params.order);
    } catch {
      this.$message({
        showClose: true,
        message: this.$t('page.agent.serviceDetail.noServiceFound'),
        type: 'error',
      });
      this.$router.push({ name: 'agentService' });
    }
    this.removeLoadingState();
  },
  destroyed() {
    this.serviceOrder = null;
  },
  methods: {
    ...mapMutations('Loading', ['addLoadingState', 'removeLoadingState']),
    confirmAction(payload) {},
  },
};
</script>

<style lang="scss"></style>
