<template>
  <div>
    <div ref="stickyNav" class="page-navigation">
      <div class="container">
        <el-menu mode="horizontal" :router="true" :default-active="activeRoute">
          <div class="el-menu__layout">
            <div class="flex-row-center">
              <div ref="stickyLogo" class="el-menu__icon">
                <img src="../../assets/images/logo_icon.svg" />
              </div>

              <div>
                <template v-for="(item, index) in navigationItems">
                  <el-menu-item
                    :key="index"
                    :index="item.path"
                    :route="item"
                    tabindex="0"
                    >{{ $t(item.meta.title) }}</el-menu-item
                  >
                </template>
              </div>
            </div>

            <div ref="stickyControls" class="el-menu__controls">
              <router-link v-if="isLoggedIn" :to="{ name: 'userAccount' }">
                <div class="page-header__profile">
                  <img
                    src="../../assets/images/user.svg"
                    class="controls__icon"
                    :title="$t('icon.userAccount')"
                  />
                </div>
              </router-link>
              <img
                v-if="isLoggedIn"
                src="../../assets/images/logout.svg"
                class="page-header__icon"
                :title="$t('page.logout.icon.title')"
                @click="logout"
              />
            </div>
          </div>
        </el-menu>
      </div>
    </div>
    <div ref="stickySpacer"></div>
  </div>
</template>
<script>
import { navs, getRoutesForNav } from '@/router/helper';
import StickyHeader from '@/mixins/StickyHeader';
import { mapGetters } from 'vuex';

export default {
  mixins: [StickyHeader],
  computed: {
    ...mapGetters('User', ['isLoggedIn']),
    navigationItems: () => getRoutesForNav(navs.main),
    activeRoute() {
      return this.$route.path;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('User/logout');
      this.$message({
        showClose: true,
        message: this.$t('page.logout.message.logoutSuccess'),
        type: 'success',
      });
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/settings';

.page-navigation {
  background-color: $color-main-navigation;
  z-index: 2001;
  .el-menu {
    background-color: transparent;
    border: 0;
  }
  .el-menu__layout {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 0;
  }
  .el-menu__icon {
    display: none;
    align-items: center;
    margin-right: 1.5rem;
    img {
      height: 30px;
    }
  }
  .el-menu__controls {
    display: none;
    align-items: center;
    .controls__icon {
      height: 25px;
    }
  }
  .el-menu-item.el-menu-item {
    display: inline-block;
    margin-right: $spacing-large;
    padding: $spacing-default 0 $spacing-small;
    height: auto;
    line-height: inherit;
    font-size: $font-size-large;
    font-weight: $font-weight-light;
    color: $--color-dark-gray;
    border-bottom: 4px solid transparent;

    &:hover {
      background-color: inherit;
      border-bottom: 4px solid darken($color-main-navigation, 10%) !important;
    }

    &.is-active {
      border-bottom: 4px solid $--color-primary;
      background-color: inherit;
      color: inherit;
    }
  }
}
</style>
