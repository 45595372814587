<template>
  <main-page :page-title="$t('page.orders.title')" :is-loading="isLoading">
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <el-input
          v-model="searchTerm"
          :placeholder="$t('form.search.placeholder')"
          prefix-icon="el-icon-search"
          size="small"
          class="mr-1"
        ></el-input>
        <router-link :to="{ name: 'customerOrderCreate' }">
          <el-button type="primary" size="small">{{
            $t('page.dashboard.control.createServiceOrder')
          }}</el-button>
        </router-link>
      </div>
    </template>

    <el-row v-if="serviceOrders.length" :gutter="20">
      <ServiceOrderListItem
        v-for="(order, key) in serviceOrders"
        :key="key"
        :order="order"
        @loading="handleLoading"
      />
    </el-row>

    <el-row v-else :gutter="20">
      <el-col :span="24">
        <el-alert
          :title="$t('table.orders.noOrders')"
          :closable="false"
          type="info"
          show-icon
        >
        </el-alert>
      </el-col>
    </el-row>

    <hr />

    <el-row :gutter="20" align="center">
      <el-col :span="24">
        <el-pagination
          v-if="!isLoading"
          background
          layout="prev, pager, next"
          :total="serviceOrdersTotalCount"
          :current-page="currentPage"
          hide-on-single-page
          @current-change="handleUpdateCurrentPage"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </main-page>
</template>
<script>
import ServiceOrderListItem from '@/components/ServiceOrderListItem';
import PaginatedSearch from '@/mixins/PaginatedSearch';

import { mapState, mapActions } from 'vuex';
export default {
  components: {
    ServiceOrderListItem,
  },
  mixins: [PaginatedSearch],
  data() {
    return {
      isLoading: true,
      storeNameSpace: 'ServiceOrder',
      searchFunctionName: 'searchServiceOrder',
    };
  },
  computed: {
    ...mapState('ServiceOrder', [
      'currentPage',
      'serviceOrders',
      'serviceOrdersTotalCount',
    ]),
  },
  async mounted() {
    await this.getServiceOrders();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('ServiceOrder', [
      'updateCurrentPage',
      'getServiceOrders',
      'searchServiceOrder',
    ]),
    handleLoading(value) {
      this.isLoading = value;
    },
  },
};
</script>
