import { login, refreshToken, create, edit } from '@/services/User.service';
import { STAFF, ADMIN } from '@/auth/roles';
import User from '@/models/User.model';

export default {
  namespaced: true,
  state: {
    user:
      new User().deserializeFromLocalStorage(
        JSON.parse(localStorage.getItem('user'))
      ) || null,
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    tokenRefreshInterval: null,
    language: null,
  },
  getters: {
    isLoggedIn: (state) => {
      return !!state.user && !!state.accessToken;
    },
    getUserRole: (state) => {
      if (state.user) return state.user.role;
      else return null;
    },
    isAdmin: (state) => {
      if (state.user) return state.user.role === ADMIN;
      else return false;
    },
    isStaff: (state) => {
      if (state.user) return state.user.role === STAFF;
      else return false;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
      localStorage.setItem('user', JSON.stringify(data));
    },
    setAccessToken(state, token) {
      state.accessToken = token;
      localStorage.setItem('accessToken', token);
    },
    setRefreshToken(state, token) {
      state.refreshToken = token;
      localStorage.setItem('refreshToken', token);
    },
    unsetUser(state) {
      window.clearInterval(state.tokenRefreshInterval);
      state.accessToken = null;
      state.refreshToken = null;
      state.tokenRefreshInterval = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
    },
    setTokenRefreshInterval(state, interval) {
      state.tokenRefreshInterval = interval;
    },
    setLanguage(state, language) {
      state.language = language;
      localStorage.setItem('userLanguage', language);
    },
  },
  actions: {
    /**
     * Log user in
     */
    async login({ commit, dispatch }, credentials) {
      const user = await login(credentials);

      // Set access and refresh token
      commit('setAccessToken', user.token);
      commit('setRefreshToken', user.refreshToken);

      // Start refresh interval
      dispatch('tokenRefresh');

      // Set user object
      commit('setUser', user);
    },
    /**
     * Log user out
     */
    logout({ commit, getters }) {
      if (getters.isLoggedIn) {
        commit('unsetUser');
      }
    },

    /**
     * Create a new user
     */
    async create({}, formData) {
      // save formData to a new Object to remove reactivity
      let payload = JSON.parse(JSON.stringify(formData));
      // Set shipping addresse to invoicing address if they are equal
      if (payload.shipAddressIsInvAddress) {
        payload.customer.invoiceAddress = payload.customer.shippingAddress;
      }
      await create(payload);
    },

    /**
     * Edit the user
     */
    async edit({ commit }, formData) {
      // save formData to a new Object to remove reactivity
      let payload = JSON.parse(JSON.stringify(formData));
      // Set shipping addresse to invoicing address if they are equal
      if (payload.shipAddressIsInvAddress) {
        payload.customer.invoiceAddress = payload.customer.shippingAddress;
      }
      await edit(payload);

      // Only set the currently logged in customer if this action is not called from the backoffice
      if (!formData.backoffice) {
        commit('setUser', new User(null).deserializeFromLocalStorage(payload));
      }
    },

    /**
     * Refresh the token every minute
     */
    tokenRefresh({ commit, state }) {
      // Let's make sure we only ever start one refresh interval
      if (state.tokenRefreshInterval) {
        return;
      }
      const intervalInMins = 2;
      const refreshInterval = intervalInMins * 60 * 1000;

      const interval = window.setInterval(async () => {
        const response = await refreshToken(state.refreshToken);
        if (response.access) {
          commit('setAccessToken', response.access);
        }
      }, refreshInterval);

      commit('setTokenRefreshInterval', interval);
    },
  },
};
