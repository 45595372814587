export default {
  data() {
    return {
      scrolled: false,
    };
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 93) {
        this.$refs.stickyNav.classList.add('page-navigation--sticky-nav');
        this.$refs.stickySpacer.style.height =
          this.$refs.stickyNav.offsetHeight + 'px';
        this.$refs.stickyLogo.style.display = 'flex';
        this.$refs.stickyControls.style.display = 'flex';
      } else {
        this.$refs.stickyNav.classList.remove('page-navigation--sticky-nav');
        this.$refs.stickySpacer.style.height = 0;
        this.$refs.stickyLogo.style.display = 'none';
        this.$refs.stickyControls.style.display = 'none';
      }
    },
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
