import i18n from '@/i18n';
import Customer from '@/models/Customer.model';
import store from '@/store';

class Manufacture {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
  }
}

class Insurance {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
  }
}

class ServiceOption {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
    this.price = payload && payload.price ? payload.price : null;
  }

  displayName() {
    return `${this.name} (+ CHF ${this.price})`;
  }
}

class Service {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
    this.description =
      payload && payload.description ? payload.description : null;
    this.price = payload && payload.price ? payload.price : null;
    this.availableOptions =
      payload && payload.availableOptions
        ? payload.availableOptions.map((option) => {
            return new ServiceOption(option);
          })
        : null;
  }
}

class ShippingMethod {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
    this.isPickup = payload && payload.isPickup ? payload.isPickup : false;
  }
}

class PaymentMethod {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.name = payload && payload.name ? payload.name : null;
  }
}

class ServiceOrder {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    this.shippingMethod =
      payload && payload.shippingMethod
        ? new ShippingMethod(payload.shippingMethod)
        : null;
    this.paymentMethod =
      payload && payload.paymentMethod
        ? new ShippingMethod(payload.paymentMethod)
        : null;
    this.isExpress = payload && payload.isExpress ? 1 : 0;
    this.status = payload && payload.status ? payload.status : null;
    this.statusDisplay =
      payload && payload.getStatusDisplay ? payload.getStatusDisplay : '';
    this.price = payload && payload.price ? payload.price : 0;
    this.shippingPrice =
      payload && payload.shippingPrice ? payload.shippingPrice : 0;
    this.paymentPrice =
      payload && payload.paymentPrice ? payload.paymentPrice : 0;
    this.expressPrice =
      payload && payload.expressPrice ? payload.expressPrice : 0;
    this.positionsPrice =
      payload && payload.positionsPrice ? payload.positionsPrice : 0;
    this.positions =
      payload && payload.positions
        ? payload.positions.map((pos) => {
            return new ServicePosition(pos);
          })
        : null;
    this.customer = new Customer(payload.customer);
    this.trackingNumber =
      payload && payload.trackingNumber ? payload.trackingNumber : '';
  }
  /**
   * Helper to identify if a order is editable or not
   */
  isEditable() {
    return this.status === 'new' || store.getters['User/isAdmin'];
  }

  /**
   * Helper to identify if a order is placed but not yet received
   */
  showHandInHelp() {
    return this.status === 'placed';
  }

  /**
   * Helper to identify if a order is in progress
   */
  isInProgress() {
    return this.status !== 'new' && this.status !== 'sent';
  }

  /**
   * Helper to identify if a order is in progress
   */
  hasInvoice() {
    return this.status === 'ready for shipping' || this.status === 'sent';
  }

  /**
   * Helper to identify if a order can be deleted
   */
  canBeDeleted() {
    return this.status === 'new';
  }

  /**
   * Helper to identify if a order can be received
   */
  canBeReceived() {
    return (
      (this.status === 'placed' || this.status === 'new') &&
      this.positions.length > 0
    );
  }

  /**
   * Helper to identify if a order can be wroked on
   */
  canBeWorkedOn() {
    return this.status === 'received';
  }

  /**
   * Helper to identify if a order can be finished
   */
  canBeFinished() {
    return this.status === 'in progress';
  }

  /**
   * Helper to identify if a order can be invoiced
   */
  canBeInvoiced() {
    return this.status === 'ready for invoice';
  }

  /**
   * Helper to identify if a order can be shipped
   */
  canBeShipped() {
    return this.status === 'ready for shipping';
  }

  /**
   * Helper to identify if a order has a tracking code
   */
  hasTrackingCode() {
    return this.trackingNumber !== '';
  }

  /**
   * Helper to identify if a order is archived
   */
  isArchived() {
    return this.status === 'sent';
  }

  /**
   * Helper for tag color
   */
  tagColor() {
    if (this.isEditable()) return 'primary';
    else if (this.isInProgress()) return 'success';
    else return 'info';
  }

  /**
   * Helper to count the amount of positions on the ServiceOrder
   */
  posCount() {
    return this.positions.length;
  }

  serviceSpeed() {
    return this.isExpress
      ? i18n.t('constants.serviceSpeeds.express')
      : i18n.t('constants.serviceSpeeds.standard');
  }
}

class Product {
  constructor(payload) {
    this.group = payload && payload.group ? payload.group : null;
    this.supplier = payload && payload.supplier ? payload.supplier : null;
    this.manufacture =
      payload && payload.manufacture ? payload.manufacture : null;
    this.name = payload && payload.name ? payload.name : '';
    this.description =
      payload && payload.description ? payload.description : '';
    this.price = payload && payload.price ? payload.price : null;
    this.purchase_price =
      payload && payload.purchase_price ? payload.purchase_price : null;
    this.image = payload && payload.image ? payload.image : null;
    this.code = payload && payload.code ? payload.code : null;
    this.manufacture_code =
      payload && payload.manufacture_code ? payload.manufacture_code : null;
  }
}

class ServicePosition {
  constructor(payload) {
    this.id = payload && payload.id ? payload.id : null;
    this.serialNumber =
      payload && payload.serialNumber ? payload.serialNumber : null;
    this.idCode = payload && payload.idCode ? payload.idCode : null;
    this.customerReference =
      payload && payload.customerReference ? payload.customerReference : null;
    this.customerComment =
      payload && payload.customerComment ? payload.customerComment : null;
    this.name = payload && payload.name ? payload.name : null;
    this.warranty = payload && payload.warranty ? payload.warranty : false;
    this.service =
      payload && payload.service ? new Service(payload.service) : null;
    this.price = payload && payload.price ? payload.price : 0;
    this.options =
      payload && payload.options
        ? payload.options.map((option) => {
            return new ServiceOption(option);
          })
        : null;
    this.order =
      payload && payload.order ? new ServiceOrder(payload.order) : null;
    this.products =
      payload && payload.products
        ? payload.products.map((product) => {
            return new Product(product);
          })
        : null;
  }

  optionsList() {
    // Reduce the options objects to a list of names
    // and join them together with ` / `
    return this.options
      .map((option) => {
        return option.name;
      })
      .join(' / ');
  }

  warrantyDisplay() {
    return this.warranty
      ? i18n.t('constants.general.yes')
      : i18n.t('constants.general.no');
  }
}

export {
  Manufacture,
  Service,
  ShippingMethod,
  PaymentMethod,
  ServiceOrder,
  ServicePosition,
  Insurance,
  Product,
};
