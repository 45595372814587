<template>
  <el-row
    v-if="order && order.shippingMethod && order.paymentMethod"
    :gutter="20"
    class="mt-2"
  >
    <el-col :span="12">
      <h2 class="mb-1 form-section">
        {{
          $t('page.backoffice.serviceOrderDetail.sectionTitle.shippingAddress')
        }}
      </h2>
      <div v-if="order.customer.type === 'private'">
        {{ order.customer.salutationDisplay }}
      </div>
      <div>{{ order.customer.fullName() }}</div>
      <div v-if="order.customer.shippingAddress.addition">
        {{ order.customer.shippingAddress.addition }}
      </div>
      <div>{{ order.customer.shippingAddress.street }}</div>
      <div>
        {{ order.customer.shippingAddress.country }}-{{
          order.customer.shippingAddress.zipCode
        }}
        {{ order.customer.shippingAddress.city }}
      </div>
    </el-col>

    <el-col :span="12">
      <h2 class="mb-1 form-section">
        {{
          $t('page.backoffice.serviceOrderDetail.sectionTitle.invoiceAddress')
        }}
      </h2>
      <div v-if="order.customer.type === 'private'">
        {{ order.customer.salutationDisplay }}
      </div>
      <div>{{ order.customer.fullName() }}</div>
      <div v-if="order.customer.invoiceAddress.addition">
        {{ order.customer.invoiceAddress.addition }}
      </div>
      <div>{{ order.customer.invoiceAddress.street }}</div>
      <div>
        {{ order.customer.invoiceAddress.country }}-{{
          order.customer.invoiceAddress.zipCode
        }}
        {{ order.customer.invoiceAddress.city }}
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      default: null,
      required: false,
    },
  },
};
</script>
<style lang="scss">
.orderDetails {
  i {
    margin-right: 0.25rem;
  }
}
</style>
