<template>
  <agent-page
    :page-title="$t('page.agent.service.title')"
    :breadcrumbs="breadcrumbs"
    class="service"
  >
    <section class="service__select">
      <ServiceSelect @select="getServiceOrder" />
    </section>
  </agent-page>
</template>

<script>
import ServiceSelect from '@/components/agent/service/ServiceSelect';

export default {
  components: {
    ServiceSelect,
  },
  data() {
    return {
      serviceOrder: null,
      breadcrumbs: [{ title: this.$t('nav.agent.service'), to: null }],
    };
  },
  methods: {
    getServiceOrder(order) {
      this.$router.push({
        name: 'agentServiceDetail',
        params: { order: order },
      });
    },
  },
};
</script>

<style lang="scss">
.service {
  &__select {
    margin: auto;
  }
}
</style>
