<template>
  <el-col :span="24">
    <div class="service-order" :class="borderColor">
      <router-link
        class="control"
        :to="{ name: 'customerOrderEdit', params: { id: order.id } }"
      >
        <div class="service-order__details">
          <span><i class="el-icon-tickets"></i> {{ order.id }}</span>
          <span
            ><i class="el-icon-money"></i> {{ order.paymentMethod.name }}</span
          >
          <span
            ><i class="el-icon-box"></i> {{ order.shippingMethod.name }}</span
          >
          <span><i class="el-icon-timer"></i> {{ order.serviceSpeed() }}</span>
        </div>
      </router-link>
      <div class="service-order__controls">
        <!-- <el-tooltip class="control" effect="dark" :content="$t('tooltips.showTracking')" placement="bottom">
          <el-button size="mini" type="info" icon="el-icon-truck" disabled="disabled" circle></el-button>
        </el-tooltip> -->
        <router-link
          class="control"
          :to="{ name: 'customerOrderEdit', params: { id: order.id } }"
        >
          <el-button
            size="mini"
            type="default"
            icon="el-icon-search"
            circle
          ></el-button>
        </router-link>

        <el-tooltip
          v-if="!order.isEditable()"
          class="control"
          effect="dark"
          :content="$t('tooltips.downloadPdf')"
          placement="bottom"
        >
          <el-button
            :loading="pdfLoading"
            size="mini"
            type="info"
            icon="el-icon-download"
            circle
            @click="downloadPdf(order)"
          ></el-button>
        </el-tooltip>

        <el-button
          v-if="order.isEditable()"
          class="control"
          size="mini"
          type="danger"
          icon="el-icon-delete"
          circle
          @click="confirm('warning', order.id)"
        ></el-button>
      </div>
    </div>
  </el-col>
</template>
<script>
import { mapActions } from 'vuex';
import ConfirmAction from '@/mixins/ConfirmAction';
import DownloadPdf from '@/mixins/DownloadPdf';
export default {
  mixins: [ConfirmAction, DownloadPdf],
  props: {
    order: {
      type: Object,
      default: null,
      required: true,
    },
  },
  computed: {
    borderColor() {
      if (this.order.isEditable()) return 'service-order--new';
      else if (this.order.isArchived()) return 'service-order--archived';
      else return 'service-order--in-progress';
    },
  },
  methods: {
    ...mapActions('ServiceOrder', ['deleteServiceOrder']),
    async confirmAction(obj) {
      await this.deleteServiceOrder(obj);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/settings';
.service-order {
  background-color: $--color-light-gray;
  border-left: 0.4rem solid $--color-dark-gray;
  border-radius: 5px;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &--new {
    border-color: $--color-primary;
  }
  &--in-progress {
    border-color: $--color-success;
  }
  &--archived {
    border-color: $--color-dark-gray;
  }
}
.service-order__details {
  color: $--color-gray;
  &:hover {
    color: lighten($--color-gray, 15);
  }
  span {
    margin-right: 2rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
.service-order__controls {
  text-align: right;
  .control {
    margin-left: 10px;
  }
}
</style>
