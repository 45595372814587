import { loadCustomers, loadCustomer } from '@/services/Customer.service';
import { loadUser } from '@/services/User.service';

export default {
  namespaced: true,
  state: {
    customers: [],
    currentPage: 1,
    customersTotalCount: 0,
    searchTerm: '',
    currentCustomer: null,
    userForCustomer: null,
  },
  mutations: {
    setCustomers(state, customers) {
      state.customers = customers;
    },
    setCustomersTotalCount(state, count) {
      state.customersTotalCount = count;
    },
    setSearchTerm(state, term) {
      state.searchTerm = term;
    },
    setCurrentPage(state, page) {
      state.currentPage = page;
    },
    setCurrentCustomer(state, customerId) {
      state.currentCustomer = customerId;
    },
    setUserForCustomer(state, user) {
      state.userForCustomer = user;
    },
  },
  actions: {
    async getCustomers({ commit, state }) {
      const response = await loadCustomers(state.currentPage, state.searchTerm);
      if (response) {
        commit('setCustomers', response.customers);
        commit('setCustomersTotalCount', response.count);
      }
    },
    /**
     * Get a single customer from the API
     */
    async getCustomer({ commit, dispatch }, id) {
      const customer = await loadCustomer(id);
      commit('setCurrentCustomer', customer);

      // If the customer has a user we map the first user to the current customer.
      // this has to be changed as there will be customers with more than one user.
      if (customer.users[0] && customer.users[0].id) {
        dispatch('getUserForCustomer', customer.users[0].id);
      }
    },
    /**
     * Get a specif user from the API
     */
    async getUserForCustomer({ commit }, id) {
      const user = await loadUser(id);
      commit('setUserForCustomer', user);
    },
    /**
     * Search the list of Customers
     * We set the current page back to the first page
     */
    async searchCustomer({ commit, dispatch }, term) {
      commit('setSearchTerm', term);
      commit('setCurrentPage', 1);
      await dispatch('getCustomers');
    },
    /**
     * Handle the change of page
     * Set the current page and reload all the customers for that page
     */
    async updateCurrentPage({ commit, dispatch }, page) {
      commit('setCurrentPage', page);
      await dispatch('getCustomers');
    },
  },
};
