import { navs } from './helper';
import { STAFF, ADMIN } from '@/auth/roles';

import BackofficeDashboard from '@/views/backoffice/Dashboard';

import BackofficeCustomerCreate from '@/views/backoffice/CustomerCreate';
import BackofficeCustomerList from '@/views/backoffice/CustomerList';
import BackofficeCustomerDetail from '@/views/backoffice/CustomerDetail';
import BackofficeCustomerEdit from '@/views/backoffice/CustomerEdit';

import BackofficeOrderCreate from '@/views/backoffice/ServiceOrderCreate';
import BackofficeOrderList from '@/views/backoffice/ServiceOrderList';
import BackofficeOrderDetail from '@/views/backoffice/ServiceOrderDetail';

export default [
  {
    path: '/backoffice',
    name: 'backofficeDashboard',
    component: BackofficeDashboard,
    meta: {
      authorize: [STAFF, ADMIN],
      navigation: navs.main,
      showTo: [STAFF, ADMIN],
      title: 'nav.backoffice.dashboard',
    },
  },
  {
    path: '/backoffice/customer',
    name: 'backofficeContact',
    component: BackofficeCustomerList,
    meta: {
      authorize: [STAFF, ADMIN],
      navigation: navs.main,
      showTo: [STAFF, ADMIN],
      title: 'nav.backoffice.contacts',
    },
  },
  {
    path: '/backoffice/customer/create',
    name: 'backofficeCustomerCreate',
    component: BackofficeCustomerCreate,
    meta: {
      authorize: [STAFF, ADMIN],
    },
  },
  {
    path: '/backoffice/customer/:id',
    name: 'backofficeCustomerDetail',
    component: BackofficeCustomerDetail,
    meta: {
      authorize: [STAFF, ADMIN],
    },
  },
  {
    path: '/backoffice/customer/:id/edit',
    name: 'backofficeCustomerEdit',
    component: BackofficeCustomerEdit,
    meta: {
      authorize: [STAFF, ADMIN],
    },
  },
  {
    path: '/backoffice/order',
    name: 'backofficeOrderList',
    component: BackofficeOrderList,
    meta: {
      authorize: [STAFF, ADMIN],
      navigation: navs.main,
      showTo: [STAFF, ADMIN],
      title: 'nav.backoffice.orders',
    },
  },
  {
    path: '/backoffice/order/create',
    name: 'backofficeOrderCreate',
    component: BackofficeOrderCreate,
    meta: {
      authorize: [STAFF, ADMIN],
    },
  },
  {
    path: '/backoffice/order/:id',
    name: 'backofficeOrderDetail',
    component: BackofficeOrderDetail,
    meta: {
      authorize: [STAFF, ADMIN],
    },
  },
];
