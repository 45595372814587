<template>
  <div class="create-account">
    <div class="create-account__form">
      <ValidationObserver ref="observer" tag="form">
        <el-form ref="loginForm" v-loading="isLoading" :model="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <ValidatedSelect
                v-model="form.customer.type"
                name="customerType"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.type') + ' *'
                "
                :disabled="true"
              >
                <el-option
                  v-for="type in selectCustomerTypes"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>
            </el-col>
            <el-col :span="12"></el-col>
          </el-row>

          <el-row v-if="form.customer.type" :gutter="20" class="mt-1">
            <el-col :span="24">
              <h2 class="mb-1 form-section">
                {{ $t('form.editAccount.sectionTitle.customer.accountInfo') }}
              </h2>
            </el-col>

            <el-col :span="12">
              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.companyName"
                label="companyName"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.companyName') + ' *'
                "
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.companyAddition"
                label="companyAddition"
                rules=""
                :placeholder="
                  $t('form.editAccount.placeholder.customer.companyAddition') +
                  ' *'
                "
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.swissUid"
                label="swissUid"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.swissUid') + ' *'
                "
              />

              <ValidatedSelect
                v-model="form.customer.salutation"
                name="salutation"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.salutation') + ' *'
                "
              >
                <el-option
                  v-for="salutation in selectSalutations"
                  :key="salutation.value"
                  :label="salutation.label"
                  :value="salutation.value"
                ></el-option>
              </ValidatedSelect>

              <ValidatedInput
                v-model="form.customer.firstName"
                label="firstName"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.firstName') + ' *'
                "
              />

              <ValidatedInput
                v-model="form.customer.lastName"
                label="lastName"
                rules="required"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.lastName') + ' *'
                "
              />
            </el-col>
            <el-col :span="12">
              <ValidatedInput
                v-model="form.customer.phone"
                label="phone"
                rules="required|phone:shippingCountry"
                :placeholder="
                  $t('form.editAccount.placeholder.customer.phone') + ' *'
                "
              />

              <ValidatedInput
                v-model="form.email"
                label="email"
                rules="required|email"
                :placeholder="$t('form.editAccount.placeholder.email') + ' *'"
                :disabled="true"
              />

              <ValidatedInput
                v-if="form.customer.type === 'business'"
                v-model="form.customer.web"
                label="web"
                :placeholder="$t('form.editAccount.placeholder.customer.web')"
              />

              <ValidatedInput
                v-if="!backoffice"
                v-model="form.password"
                type="password"
                label="password"
                :placeholder="$t('form.editAccount.placeholder.password')"
                vid="password"
              />

              <ValidatedInput
                v-if="!backoffice"
                v-model="form.password2"
                type="password"
                label="password2"
                :rules="
                  !backoffice && form.password
                    ? 'required|confirmed:password'
                    : ''
                "
                :placeholder="$t('form.editAccount.placeholder.password2')"
              />
            </el-col>
          </el-row>

          <el-row :gutter="20" class="mt-1">
            <el-col :span="12">
              <h2 class="mb-1 form-section">
                {{
                  $t('form.editAccount.sectionTitle.customer.shippingAddress')
                }}
              </h2>

              <ValidatedInput
                v-model="form.customer.shippingAddress.street"
                label="shippingStreet"
                rules="required"
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.shippingAddress.street'
                  ) + ' *'
                "
              />

              <ValidatedInput
                v-model="form.customer.shippingAddress.addition"
                label="shippingAddition"
                rules=""
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.shippingAddress.addition'
                  )
                "
              />

              <ValidatedSelect
                v-model="form.customer.shippingAddress.country"
                vid="shippingCountry"
                name="shippingCountry"
                rules="required"
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.shippingAddress.country'
                  ) + ' *'
                "
              >
                <el-option
                  v-for="type in selectCountries"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>

              <el-row :gutter="20">
                <el-col :span="8">
                  <ValidatedInput
                    v-model="form.customer.shippingAddress.zipCode"
                    label="shippingZipCode"
                    rules="required|zip"
                    :placeholder="
                      $t(
                        'form.editAccount.placeholder.customer.shippingAddress.zipCode'
                      ) + ' *'
                    "
                    :disabled="!form.customer.shippingAddress.country"
                  />
                </el-col>
                <el-col :span="16">
                  <ValidatedSelect
                    v-model="form.customer.shippingAddress.city"
                    :loading="isLoadingShipTown"
                    name="shippingCity"
                    rules="required"
                    :placeholder="
                      $t(
                        'form.editAccount.placeholder.customer.shippingAddress.city'
                      ) + ' *'
                    "
                    :disabled="!selectShipTowns.length"
                  >
                    <el-option
                      v-for="(town, key) in selectShipTowns"
                      :key="key"
                      :label="town['place name']"
                      :value="town['place name']"
                    ></el-option>
                  </ValidatedSelect>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <h2 class="mb-1 form-section">
                {{
                  $t('form.editAccount.sectionTitle.customer.invoiceAddress')
                }}
              </h2>

              <ValidatedInput
                v-model="form.customer.invoiceAddress.street"
                label="invoiceStreet"
                :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.invoiceAddress.street'
                  ) + ' *'
                "
                :disabled="form.shipAddressIsInvAddress"
              />

              <ValidatedInput
                v-model="form.customer.invoiceAddress.addition"
                label="invoiceAddition"
                rules=""
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.invoiceAddress.addition'
                  )
                "
                :disabled="form.shipAddressIsInvAddress"
              />

              <ValidatedSelect
                v-model="form.customer.invoiceAddress.country"
                name="invoiceCountry"
                :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                :placeholder="
                  $t(
                    'form.editAccount.placeholder.customer.invoiceAddress.country'
                  ) + ' *'
                "
                :disabled="form.shipAddressIsInvAddress"
              >
                <el-option
                  v-for="type in selectCountries"
                  :key="type.value"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </ValidatedSelect>

              <el-row :gutter="20">
                <el-col :span="8">
                  <ValidatedInput
                    v-model="form.customer.invoiceAddress.zipCode"
                    label="invoiceZipCode"
                    :rules="!form.shipAddressIsInvAddress ? 'required|zip' : ''"
                    :placeholder="
                      $t(
                        'form.editAccount.placeholder.customer.invoiceAddress.zipCode'
                      ) + ' *'
                    "
                    :disabled="
                      form.shipAddressIsInvAddress ||
                      !form.customer.invoiceAddress.country
                    "
                  />
                </el-col>
                <el-col :span="16">
                  <ValidatedSelect
                    v-model="form.customer.invoiceAddress.city"
                    :loading="isLoadingInvTown"
                    name="invoiceCity"
                    :rules="!form.shipAddressIsInvAddress ? 'required' : ''"
                    :placeholder="
                      $t(
                        'form.editAccount.placeholder.customer.invoiceAddress.city'
                      ) + ' *'
                    "
                    :disabled="
                      !selectInvTowns.length || form.shipAddressIsInvAddress
                    "
                  >
                    <el-option
                      v-for="(town, key) in selectInvTowns"
                      :key="key"
                      :label="town['place name']"
                      :value="town['place name']"
                    ></el-option>
                  </ValidatedSelect>
                </el-col>
              </el-row>
            </el-col>

            <el-col>
              <el-form-item class="flex-row-center">
                <el-switch v-model="form.shipAddressIsInvAddress"></el-switch>
                <span class="ml-1 display-ib">{{
                  $t('form.editAccount.placeholder.shipAddressIsInvAddress')
                }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <hr />
          <el-row>
            <el-col>
              <div class="flex-row-center justify-between">
                <el-form-item>
                  <router-link
                    v-if="backoffice"
                    :to="{
                      name: 'backofficeCustomerDetail',
                      params: { id: $route.params.id },
                    }"
                    tag="el-button"
                    >{{ $t('form.control.cancel') }}</router-link
                  >
                  <router-link
                    v-else
                    :to="{ name: 'userAccount' }"
                    tag="el-button"
                    >{{ $t('form.control.cancel') }}</router-link
                  >
                </el-form-item>

                <el-form-item>
                  <el-button
                    type="primary"
                    class="el-button--block"
                    @click="submitForm"
                    >{{ $t('form.control.save') }}</el-button
                  >
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import _ from 'lodash';

import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';
import ValidatedSelect from '@/components/inputs/ValidatedSelect';

export default {
  components: {
    ValidationObserver,
    ValidatedInput,
    ValidatedSelect,
  },
  props: {
    backoffice: {
      type: Boolean,
      default: false,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingShipTown: false,
      isLoadingInvTown: false,
      selectCustomerTypes: [
        { label: this.$t('form.option.privateCustomer'), value: 'private' },
        { label: this.$t('form.option.businessCustomer'), value: 'business' },
      ],
      selectCountries: [
        { label: this.$t('form.option.switzerland'), value: 'CH' },
        { label: this.$t('form.option.liechtenstein'), value: 'LI' },
      ],
      selectSalutations: [
        { label: this.$t('form.option.mr'), value: 'mr' },
        { label: this.$t('form.option.mrs'), value: 'mrs' },
      ],
      selectShipTowns: [],
      selectInvTowns: [],
      form: {
        shipAddressIsInvAddress: true,
      },
    };
  },
  computed: {
    redirecRoute() {
      return this.backoffice
        ? {
            name: 'backofficeCustomerDetail',
            params: { id: this.user.customer.id },
          }
        : { name: 'userAccount' };
    },
  },
  watch: {
    'form.customer.shippingAddress.country': function () {
      if (!this.isLoading) {
        this.form.customer.shippingAddress.zipCode = '';
        this.selectShipTowns = [];
        this.form.customer.shippingAddress.city = null;
      }
    },
    'form.customer.invoiceAddress.country': function () {
      if (!this.isLoading) {
        this.form.customer.invoiceAddress.zipCode = '';
        this.selectInvTowns = [];
        this.form.customer.invoiceAddress.city = null;
      }
    },
    'form.customer.shippingAddress.zipCode': function (zipCode) {
      if (zipCode.length === 4 && !this.isLoading) {
        this.zipLookup(zipCode, 'Ship', true);
      }
    },
    'form.customer.invoiceAddress.zipCode': function (zipCode) {
      if (zipCode.length === 4 && !this.isLoading) {
        this.zipLookup(zipCode, 'Inv', true);
      }
    },
    'form.type': function () {
      if (this.form.type === 'private' && !this.isLoading) {
        this.clearBussinessFields();
      }
      if (this.form.type === 'business' && !this.isLoading) {
        this.clearPrivateFields();
      }
    },
    'form.shipAddressIsInvAddress': function () {
      if (this.form.shipAddressIsInvAddress && !this.isLoading) {
        this.clearInvAddressFields();
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.form = JSON.parse(JSON.stringify(Object.assign(this.form, this.user)));

    // Initialize shipping towns based on zip code as we always have a shipping address
    this.zipLookup(this.form.customer.shippingAddress.zipCode, 'Ship', false);

    // Check if both customer addresses are the same
    if (
      _.isEqual(
        this.form.customer.shippingAddress,
        this.form.customer.invoiceAddress
      ) ||
      !Object.keys(this.form.customer.invoiceAddress).length
    ) {
      this.clearInvAddressFields();
      this.form.shipAddressIsInvAddress = true;
    } else {
      // If we have a invoicing address initialize the invoice towns based on zip code
      this.zipLookup(this.form.customer.invoiceAddress.zipCode, 'Inv', false);
      this.form.shipAddressIsInvAddress = false;
    }

    await this.$nextTick();
    this.isLoading = false;
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        this.form.backoffice = this.backoffice;
        this.editAccount();
      } else {
        this.$message({
          showClose: true,
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
    },
    async editAccount() {
      try {
        await this.$store.dispatch('User/edit', this.form);
        this.$message({
          showClose: true,
          message: this.$t('page.editAccount.message.editSuccess'),
          type: 'success',
        });
        this.$router.push(this.redirecRoute);
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t('page.editAccount.message.editError'),
          type: 'error',
        });
        this.isLoading = false;
      }
    },
    clearTown(addressCity, townsType) {
      this[`select${townsType}Towns`] = [];
      this[addressCity] = null;
    },
    zipLookup(zipCode, townsType, preselect) {
      let country = null;
      switch (townsType) {
        case 'Inv':
          country = this.form.customer.invoiceAddress.country;
          break;
        default:
          country = this.form.customer.shippingAddress.country;
          break;
      }
      this[`isLoading${townsType}Town`] = true;
      axios
        .get(`https://api.zippopotam.us/${country}/${zipCode}`)
        .then((response) => {
          this[`select${townsType}Towns`] = response.data.places;
          if (preselect) {
            this.preselectCity(this[`select${townsType}Towns`], townsType);
          }
          this[`isLoading${townsType}Town`] = false;
        })
        .catch((error) => {
          this[`isLoading${townsType}Town`] = false;
          this.$message({
            showClose: true,
            message: this.$t('page.editAccount.message.wrongZip'),
            type: 'error',
          });
        });
    },
    preselectCity(city, addressType) {
      if (city.length > 0) {
        switch (addressType) {
          case 'Inv':
            this.form.customer.invoiceAddress.city = city[0]['place name'];
            break;
          default:
            this.form.customer.shippingAddress.city = city[0]['place name'];
            break;
        }
      }
    },
    clearBussinessFields() {
      this.form.companyName = null;
      this.form.companyAddition = null;
      this.form.swissUid = null;
      this.form.web = null;
    },
    clearPrivateFields() {
      this.form.salutation = null;
    },
    clearInvAddressFields() {
      this.form.customer.invoiceAddress.street = null;
      this.form.customer.invoiceAddress.addition = null;
      this.form.customer.invoiceAddress.country = null;
      this.form.customer.invoiceAddress.zipCode = '';
      this.form.customer.invoiceAddress.city = null;
    },
  },
};
</script>
