import '@/assets/scss/main.scss';
import i18n from './i18n';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import ElementLocale from 'element-ui/lib/locale';
import VueParticles from 'vue-particles';
import VueMeta from 'vue-meta';
import './validation';

// import all the element ui components we need
import '@/components/_ElementUI';
// import all our global components
import '@/components/_global';

ElementLocale.i18n((key, value) => i18n.t(key, value));

Vue.config.productionTip = false;
Vue.use(VueParticles);
Vue.use(VueMeta);

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
