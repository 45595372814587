<template>
  <main-page :page-title="$t('page.backoffice.serviceOrders.title')">
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <el-input
          v-model="searchTerm"
          class="mr-1"
          :placeholder="$t('page.backoffice.serviceOrders.search')"
          prefix-icon="el-icon-search"
          size="small"
        ></el-input>
        <el-button type="primary" size="small">{{
          $t('page.backoffice.serviceOrders.new')
        }}</el-button>
      </div>
    </template>

    <el-row :gutter="20" class="mb-2">
      <el-col :span="24">
        <OrdersTable :orders="serviceOrders" :loading="isLoading" />
      </el-col>
    </el-row>

    <el-row :gutter="20" align="center">
      <el-col :span="24">
        <el-pagination
          v-if="!isLoading"
          background
          layout="prev, pager, next"
          :total="serviceOrdersTotalCount"
          :current-page="currentPage"
          hide-on-single-page
          @current-change="handleUpdateCurrentPage"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </main-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import OrdersTable from '@/components/backoffice/OrdersTable';
import PaginatedSearch from '@/mixins/PaginatedSearch';

export default {
  components: {
    OrdersTable,
  },
  mixins: [PaginatedSearch],
  data() {
    return {
      isLoading: true,
      storeNameSpace: 'ServiceOrder',
      searchFunctionName: 'searchServiceOrder',
    };
  },
  computed: {
    ...mapState('ServiceOrder', [
      'currentPage',
      'serviceOrders',
      'serviceOrdersTotalCount',
    ]),
  },
  async mounted() {
    this.isLoading = true;
    await this.getServiceOrders();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('ServiceOrder', [
      'updateCurrentPage',
      'getServiceOrders',
      'searchServiceOrder',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.icon {
  height: 25px;
}
</style>
