<template>
  <el-row v-loading="isLoading" :gutter="20">
    <hr />
    <ValidationObserver ref="observer" tag="form">
      <el-form ref="servicePositionForm" :model="form">
        <el-col :span="24" class="mt-05">
          <h2>{{ $t('page.servicePosition.sectionTitle.serviceDetails') }}</h2>
        </el-col>

        <el-col :span="12">
          <ValidatedInput
            v-model="form.serialNumber"
            label="serialNumber"
            :placeholder="$t('form.service.placeholder.serialNumber')"
          />
        </el-col>

        <el-col v-if="serviceIsFox" :span="12">
          <ValidatedInput
            v-model="form.idCode"
            label="idCode"
            rules="idCode"
            :placeholder="$t('form.service.placeholder.idCode')"
          />
        </el-col>

        <el-col :span="12">
          <ValidatedInput
            v-model="form.customerReference"
            label="customerReference"
            :placeholder="$t('form.service.placeholder.customerReference')"
          />
        </el-col>

        <el-col :span="24">
          <ValidatedInput
            v-model="form.name"
            type="textarea"
            label="description"
            :placeholder="$t('form.service.placeholder.description')"
          />
        </el-col>

        <el-col :span="24">
          <el-form-item class="flex-row-center">
            <el-switch v-model="form.warranty"></el-switch>
            <span class="ml-1 display-ib">{{
              $t('form.service.placeholder.warranty')
            }}</span>
          </el-form-item>
          <el-alert
            v-if="form.warranty"
            :title="$t('page.servicePosition.messages.warranty')"
            type="warning"
            :closable="false"
            show-icon
          ></el-alert>
        </el-col>

        <el-col :span="24" class="mt-05">
          <h2>
            {{ $t('page.servicePosition.sectionTitle.serviceAdditions') }}
          </h2>
        </el-col>

        <el-col :span="24">
          <el-checkbox-group v-model="form.options">
            <el-checkbox
              v-for="(option, key) in position.service.availableOptions"
              :key="key"
              :label="option.id"
              >{{ option.displayName() }}</el-checkbox
            >
          </el-checkbox-group>
        </el-col>

        <el-col :span="24">
          <hr />
          <el-form-item class="mb-0">
            <div class="flex-row-center justify-between">
              <el-button type="default" size="small" @click="cancelForm">{{
                $t('form.control.cancel')
              }}</el-button>
              <el-button type="primary" size="small" @click="submitForm">{{
                $t('form.control.save')
              }}</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </ValidationObserver>
  </el-row>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    ValidationObserver,
    ValidatedInput,
  },
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      form: {
        serialNumber: null,
        idCode: null,
        customerReference: null,
        name: null,
        warranty: false,
        order: null,
        options: [],
      },
    };
  },
  computed: {
    ...mapState('ServiceOrder', ['currentServiceOrder']),
    serviceIsFox() {
      return true; // includes(this.selectedManufacture.name, 'Fox')
    },
  },
  mounted() {
    Object.assign(this.form, this.position);
    // Reduce options objects to a list of id to initialize checkboxes
    this.form.options = this.form.options.map((option) => {
      return option.id;
    });
  },
  methods: {
    ...mapActions('ServiceOrder', ['updateServicePosition']),
    cancelForm() {
      this.$emit('toggleEditForm');
    },
    /**
     * Submit the service position form to save the edited service position
     */
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        await this.editPosition();
        this.$emit('toggleEditForm');
      } else {
        this.$message({
          showClose: true,
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
    },
    /**
     * Saving the new service position
     */
    async editPosition() {
      // add the position id and the order to the payload
      this.form.id = this.position.id;
      this.form.order = this.currentServiceOrder.id;

      // remove service object and just pass in the service id
      this.form.service = this.form.service.id;

      try {
        await this.updateServicePosition(this.form);
        this.$message({
          showClose: true,
          message: this.$t('page.servicePosition.messages.updateSuccess'),
          type: 'success',
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t('page.servicePosition.messages.updateError'),
          type: 'error',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/settings';

h2 {
  margin-bottom: 1rem;
}
.manufactures-list,
.service-list {
  min-height: 5rem;
}
</style>
