import Vue from 'vue';

import PlanePage from '@/layout/PlanePage';
import MainPage from '@/layout/MainPage';
import LoginPage from '@/layout/LoginPage';
import AgentPage from '@/layout/AgentPage';

import PageHeader from '@/components/customer/PageHeader';
import PageFooter from '@/components/customer/PageFooter';
import PageNavigation from '@/components/customer/PageNavigation';

import IconBase from '@/components/icons/IconBase';

Vue.component('plane-page', PlanePage);
Vue.component('main-page', MainPage);
Vue.component('login-page', LoginPage);
Vue.component('agent-page', AgentPage);

Vue.component('page-header', PageHeader);
Vue.component('page-footer', PageFooter);
Vue.component('page-navigation', PageNavigation);

Vue.component('icon-base', IconBase);
