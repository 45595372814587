import i18n from './i18n';

import { extend, configure } from 'vee-validate';
import { required, email, confirmed } from 'vee-validate/dist/rules';

// const swissPhoneNumber = /(\+41)\s?(\d{2})\s?(\d{3})\s?(\d{2})\s?(\d{2})/;
// const liePhoneNumber = /(\+423)\s?(\d{0})\s?(\d{3})\s?(\d{2})\s?(\d{2})/;

configure({
  mode: 'passive',
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

// Install required rule and message.
extend('required', required);

// Install email rule and message.
extend('email', email);

// Install confirm rule and message.
extend('confirmed', confirmed);

// Install zipCode rule and message.
extend('zip', {
  message() {
    return i18n.t('validation.zipCode');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({ valid: value.length === 4 });
    });
  },
});

// Intsall idCode rule and message.
extend('idCode', {
  message() {
    return i18n.t('validation.idCode');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({ valid: value.length === 4 });
    });
  },
});

// Install phone rule and message
extend('phone', {
  message(value, { country }) {
    if (country === 'LI') {
      return i18n.t('validation.liPhoneNumber');
    } else {
      return i18n.t('validation.chPhoneNumber');
    }
  },
  validate(value, { country }) {
    let validPhone = false;

    if (country === 'LI') {
      validPhone = true; // liePhoneNumber.exec(value) !== null;
    } else {
      validPhone = true; // swissPhoneNumber.exec(value) !== null;
    }

    return new Promise((resolve) => {
      resolve({ valid: validPhone });
    });
  },
  params: [{ name: 'country', isTarget: true }],
});
