<template>
  <main-page :page-title="$t('page.userAccount.title')">
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <router-link :to="{ name: 'editAccount' }">
          <el-button type="primary" size="small">{{
            $t('page.userAccount.control.edit')
          }}</el-button>
        </router-link>
      </div>
    </template>

    <UserDetail :user="user" />
  </main-page>
</template>
<script>
import { mapState } from 'vuex';
import UserDetail from '@/components/UserDetail';

export default {
  components: {
    UserDetail,
  },
  computed: {
    ...mapState('User', ['user']),
  },
};
</script>
