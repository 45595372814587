<template>
  <el-avatar :size="60" :src="avatarUrl" />
</template>

<script>
export default {
  computed: {
    avatarUrl() {
      return require('@/assets/images/kevin.jpg');
    },
  },
};
</script>

<style lang="scss" scoped></style>
