import { unpack } from './helpers';
import http from './http';

import { Product } from '@/models/ServiceOrder.model';

// Load products from teh API
// and map them to a Product object
export const searchProducts = async (query) => {
  const response = await unpack(
    http.get('/product/', { params: { search: query } })
  );
  return response.map((payload) => {
    return new Product(payload);
  });
};
