<template>
  <main-page :page-title="$t('page.createServiceOrder.title')">
    <ServiceOrderForm></ServiceOrderForm>
  </main-page>
</template>
<script>
import ServiceOrderForm from '@/components/ServiceOrderForm';

export default {
  components: {
    ServiceOrderForm,
  },
};
</script>
