<template>
  <el-container class="el-container--fullheight main-layout">
    <el-header height="auto" class="page-header">
      <page-header />
    </el-header>

    <el-main>
      <div class="page-main container">
        <el-row type="flex" justify="space-between">
          <h1 v-if="pageTitle" class="page-title">{{ pageTitle }}</h1>
        </el-row>
        <slot></slot>
      </div>
    </el-main>

    <el-footer class="page-footer">
      <page-footer></page-footer>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'PlanePage',
  props: {
    pageTitle: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.page-main {
  height: 100%;
}
.page-title {
  margin: 2rem 0 2.5rem;
}
</style>
