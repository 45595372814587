<template>
  <el-row
    v-if="order && order.shippingMethod && order.paymentMethod"
    :gutter="20"
    class="mt-2"
  >
    <el-col :span="24">
      <h2 class="mb-1 form-section">
        {{ $t('page.backoffice.serviceOrderDetail.sectionTitle.positions') }}
      </h2>
      <el-table
        :data="order.positions"
        :empty-text="$t('table.positions.noData')"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-row :gutter="20">
              <el-col :span="12">
                <p v-if="scope.row.name !== ''">
                  <span class="h-text-bold"
                    >{{
                      $t('page.backoffice.serviceOrderDetail.table.comment')
                    }}:</span
                  >
                  {{ scope.row.name }}
                </p>
                <p v-if="scope.row.customerReference !== ''">
                  <span class="h-text-bold"
                    >{{
                      $t(
                        'page.backoffice.serviceOrderDetail.table.customerReference'
                      )
                    }}:</span
                  >
                  {{ scope.row.customerReference }}
                </p>
              </el-col>
              <el-col :span="12" class="h-text-right">
                <el-button type="primary" size="mini" @click="toggleEditForm">
                  <i class="el-icon-edit"></i>
                  {{
                    $t(
                      'page.backoffice.serviceOrderDetail.controls.positionEdit'
                    )
                  }}
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  @click="confirm('warning', scope.row.id)"
                >
                  <i class="el-icon-delete"></i>
                  {{
                    $t(
                      'page.backoffice.serviceOrderDetail.controls.positionDelete'
                    )
                  }}
                </el-button>
              </el-col>
            </el-row>

            <el-row>
              <el-col>
                <!-- Position edit form -->
                <ServicePositionEditForm
                  v-if="showEditForm"
                  :position="scope.row"
                  @toggleEditForm="toggleEditForm"
                />
              </el-col>
            </el-row>
          </template>
        </el-table-column>

        <el-table-column label="ID" prop="id" width="70" />

        <el-table-column
          :label="$t('page.backoffice.serviceOrderDetail.table.service')"
          prop="service.name"
        />

        <el-table-column
          :label="$t('page.backoffice.serviceOrderDetail.table.serialNumber')"
          prop="serialNumber"
        />

        <el-table-column
          :label="$t('page.backoffice.serviceOrderDetail.table.idCode')"
          prop="idCode"
        />

        <el-table-column
          :label="$t('page.backoffice.serviceOrderDetail.table.options')"
        >
          <template slot-scope="scope">
            {{ scope.row.optionsList() }}
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('page.backoffice.serviceOrderDetail.table.warranty')"
        >
          <template slot-scope="scope">
            {{ scope.row.warrantyDisplay() }}
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>
<script>
import { mapActions } from 'vuex';
import ConfirmAction from '@/mixins/ConfirmAction';
import ServicePositionEditForm from '@/components/ServicePositionEditForm';

export default {
  components: {
    ServicePositionEditForm,
  },
  mixins: [ConfirmAction],
  props: {
    order: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      showEditForm: false,
    };
  },
  methods: {
    ...mapActions('ServiceOrder', ['deleteServicePosition']),
    async confirmAction(obj) {
      await this.deleteServicePosition(obj);
    },
    toggleEditForm() {
      this.showEditForm = !this.showEditForm;
    },
  },
};
</script>
<style lang="scss" scoped></style>
