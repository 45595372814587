import http from '@/services/http';
export default {
  data() {
    return {
      pdfLoading: false,
    };
  },
  methods: {
    downloadPdf(serviceOrder) {
      this.pdfLoading = true;
      http
        .get('/pdf/', {
          params: {
            'order-id': serviceOrder.id,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `AU-${serviceOrder.id}.pdf`;
          link.click();
          this.pdfLoading = false;
        });
    },
    downloadInvoice(serviceOrder) {
      this.pdfLoading = true;
      http
        .get(`/order/${serviceOrder.id}/`, {
          params: {
            pdf: 'invoice',
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `RG-${serviceOrder.id}.pdf`;
          link.click();
          this.pdfLoading = false;
        });
    },
  },
};
