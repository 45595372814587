<template>
  <el-row :gutter="15">
    <el-col v-for="(pos, key) in order.positions" :key="key" :span="12">
      <div class="position">
        <router-link
          :to="{ name: 'agentServicePositionDetail', params: { pos: pos.id } }"
        >
          {{ pos.service.name }}
        </router-link>
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/settings';
.position {
  padding: 1rem;
  border-radius: 10px;
  background-color: $--color-light-gray;
  margin-bottom: 1rem;
}
</style>
