import _ from 'lodash';
import routes from './routes';
import store from '@/store';

/**
 * Defines the naviagations we use in the app
 */
export const navs = {
  main: 'main',
};

/**
 * Get a list of routes for a specific navigation
 */
export const getRoutesForNav = (name) => {
  return (
    _.chain(routes)
      .filter((r) => {
        /* Meta can be an Object or a String -> cast it to array */
        const nav = _.castArray(_.get(r, 'meta.navigation'));
        return _.includes(nav, name);
      })
      // filter out routes user isn't allowed to see when he's not an admin or a staff member
      .filter((r) => {
        const showTo = _.get(r, 'meta.showTo');
        const userRole = store.getters['User/getUserRole'];
        if (showTo && _.includes(showTo, userRole)) {
          return true;
        } else {
          return false;
        }
      })

      .value()
  );
};
