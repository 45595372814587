import { CUSTOMER, STAFF, ADMIN } from '@/auth/roles';
import { navs } from './helper';

import CustomerDashboard from '@/views/customer/Dashboard';
import Orders from '@/views/customer/Orders';
import Finances from '@/views/customer/Finances';
import ServiceOrderCreate from '@/views/customer/ServiceOrderCreate';
import ServiceOrderView from '@/views/customer/ServiceOrderView';

export default [
  {
    path: '/customer',
    name: 'customerDashboard',
    component: CustomerDashboard,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
      navigation: navs.main,
      showTo: [CUSTOMER],
      title: 'nav.customer.dashboard',
    },
  },
  {
    path: '/customer/order',
    name: 'customerOrder',
    component: Orders,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
      navigation: navs.main,
      showTo: [CUSTOMER],
      title: 'nav.customer.order',
    },
  },
  {
    path: '/customer/order/create',
    name: 'customerOrderCreate',
    component: ServiceOrderCreate,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
    },
  },
  {
    path: '/customer/order/:id',
    name: 'customerOrderEdit',
    component: ServiceOrderView,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
    },
  },
  {
    path: '/customer/finance',
    name: 'customerFinance',
    component: Finances,
    meta: {
      authorize: [CUSTOMER, STAFF, ADMIN],
      navigation: navs.main,
      showTo: [CUSTOMER],
      title: 'nav.customer.finance',
    },
  },
];
