<template>
  <main-page :page-title="pageTitle" :is-loading="isLoading">
    <ServiceOrderForm v-if="!isLoading" :order="currentServiceOrder" />

    <!-- List all positions currentyl on the service order -->
    <ServicePositionListItem
      v-for="(position, key) in currentServiceOrder.positions"
      :key="key"
      :position="position"
      :order="currentServiceOrder"
    />

    <!-- Order totals -->
    <ServiceOrderTotals v-if="hasPositions" :order="currentServiceOrder" />
    <hr v-if="hasPositions" />

    <!-- Form to create a new position on the service order -->
    <ServicePositionForm
      v-if="!isLoading && showForm"
      :order="currentServiceOrder"
      @toggleForm="toggleCreateForm"
    />

    <div v-if="orderIsEditable && !showForm">
      <div class="h-text-right mb-1">
        <span class="mr-1">{{ $t('form.service.controls.terms') }}</span>
        <el-checkbox v-model="terms" />
      </div>
    </div>

    <!-- Step by step guide how to send in an order -->
    <ServiceOrderHandInHelp v-if="showHelp">
      <template v-slot:dowload-button>
        <el-button
          :loading="pdfLoading"
          type="default"
          size="small"
          icon="el-icon-download"
          @click="downloadPdf(currentServiceOrder)"
          >{{ $t('form.service.controls.downlaodPdf') }}</el-button
        >
      </template>
    </ServiceOrderHandInHelp>

    <div v-if="orderIsEditable" class="flex-row-center justify-between">
      <el-button
        v-if="!isLoading && !showForm"
        type="primary"
        size="small"
        icon="el-icon-circle-plus-outline"
        @click="toggleCreateForm"
        >{{ $t('form.service.controls.addService') }}</el-button
      >
      <el-button
        v-if="!isLoading && showForm && hasPositions"
        type="default"
        size="small"
        @click="toggleCreateForm"
        >{{ $t('form.control.cancel') }}</el-button
      >
      <el-button
        v-if="!isLoading && !showForm"
        type="success"
        size="small"
        icon="el-icon-right"
        :disabled="!hasPositions"
        :loading="handingIn"
        @click="handInOrder"
        >{{ $t('form.service.controls.placeOrder') }}</el-button
      >
    </div>

    <div
      v-if="currentServiceOrder && !orderIsEditable && !showHelp"
      class="flex-row-center justify-between"
    >
      <el-button
        v-if="!currentServiceOrder.hasInvoice"
        :loading="pdfLoading"
        type="default"
        size="small"
        icon="el-icon-download"
        @click="downloadPdf(currentServiceOrder)"
        >{{ $t('form.service.controls.downlaodPdf') }}</el-button
      >
      <el-button
        v-else
        :loading="pdfLoading"
        type="default"
        size="small"
        icon="el-icon-download"
        @click="downloadInvoice(currentServiceOrder)"
        >{{ $t('form.service.controls.downloadInvoice') }}</el-button
      >
    </div>
  </main-page>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import DownloadPdf from '@/mixins/DownloadPdf';
import ServiceOrderForm from '@/components/ServiceOrderForm';
import ServiceOrderTotals from '@/components/ServiceOrderTotals';
import ServicePositionForm from '@/components/ServicePositionForm';
import ServicePositionListItem from '@/components/ServicePositionListItem';
import ServiceOrderHandInHelp from '@/components/ServiceOrderHandInHelp';

export default {
  components: {
    ServiceOrderForm,
    ServiceOrderTotals,
    ServicePositionForm,
    ServicePositionListItem,
    ServiceOrderHandInHelp,
  },
  mixins: [DownloadPdf],
  data() {
    return {
      isLoading: true,
      handingIn: false,
      createPosition: false,
      terms: false,
    };
  },
  computed: {
    ...mapState('ServiceOrder', ['currentServiceOrder']),
    pageTitle() {
      return (
        this.$t('page.editServiceOrder.title') +
        ': ' +
        this.currentServiceOrder.id
      );
    },
    showForm() {
      return (
        (this.currentServiceOrder &&
          !this.currentServiceOrder.positions.length) ||
        this.createPosition
      );
    },
    hasPositions() {
      return (
        this.currentServiceOrder.positions &&
        this.currentServiceOrder.positions.length
      );
    },
    orderIsEditable() {
      return (
        !isEmpty(this.currentServiceOrder) &&
        this.currentServiceOrder.isEditable()
      );
    },
    showHelp() {
      return (
        !isEmpty(this.currentServiceOrder) &&
        this.currentServiceOrder.showHandInHelp()
      );
    },
  },
  async mounted() {
    await this.getServiceOrder(this.$route.params.id);
    // Check if there is some positions on the service order and and set `createPosition` accordingly
    if (!this.currentServiceOrder.positions.length) this.createPosition = true;
    this.isLoading = false;
  },
  methods: {
    ...mapActions('ServiceOrder', ['getServiceOrder', 'handInServiceOrder']),
    toggleCreateForm() {
      if (
        this.currentServiceOrder &&
        !this.currentServiceOrder.positions.length
      )
        this.createPosition = true;
      this.createPosition = !this.createPosition;
    },
    async handInOrder() {
      // make sure the customer has accepted the terms

      if (!this.terms) {
        this.$message({
          showClose: true,
          message: this.$t('form.service.messages.termsError'),
          type: 'error',
        });
      } else {
        this.handingIn = true;
        await this.handInServiceOrder();
        this.$message({
          showClose: true,
          message: this.$t('form.service.messages.handInSuccess'),
          type: 'success',
        });
      }
    },
  },
};
</script>
