<template>
  <agent-page :page-title="pageTitle" :breadcrumbs="breadcrumbs">
    <template v-slot:page-controls>
      <div class="flex justify-between">
        <el-button
          v-if="servicePosition"
          type="success"
          size="small"
          icon="el-icon-check"
          @click="confirm('info', servicePosition.id)"
          >{{ $t('page.agent.servicePositionDetail.positionDone') }}</el-button
        >
      </div>
    </template>

    <section v-if="servicePosition" class="props">
      <el-row :gutter="20">
        <el-col :span="8">
          <ServicePositionProp
            :label="$t('page.agent.servicePositionDetail.serialNumber')"
            :value="servicePosition.serialNumber"
          />
        </el-col>
        <el-col :span="8">
          <ServicePositionProp
            :label="$t('page.agent.servicePositionDetail.idCode')"
            :value="servicePosition.idCode"
          />
        </el-col>
        <el-col :span="8">
          <ServicePositionProp
            :label="$t('page.agent.servicePositionDetail.totalPrice')"
            :value="`CHF ${servicePosition.price}.00`"
          />
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <ServicePositionProp
            :label="$t('page.agent.servicePositionDetail.comment')"
            :value="servicePosition.customerComment"
          />
        </el-col>
        <el-col :span="12">
          <ServicePositionOptions :options="servicePosition.options" />
        </el-col>
      </el-row>
    </section>

    <section v-if="servicePosition" class="products">
      <h2>{{ $t('page.agent.servicePositionDetail.products') }}</h2>
      <ServicePositionProductForm :position="servicePosition" />
      <ServicePositionProductList :position="servicePosition" />
    </section>
  </agent-page>
</template>
<script>
import { mapMutations } from 'vuex';
import { loadPosition } from '@/services/ServiceOrder.service';
import ConfirmAction from '@/mixins/ConfirmAction';
import ServicePositionProductList from '@/components/agent/service/ServicePositionProductList';
import ServicePositionProductForm from '@/components/agent/service/ServicePositionProductForm';
import ServicePositionProp from '@/components/agent/service/ServicePositionProp';
import ServicePositionOptions from '@/components/agent/service/ServicePositionOptions';

export default {
  components: {
    ServicePositionProductList,
    ServicePositionProductForm,
    ServicePositionProp,
    ServicePositionOptions,
  },
  mixins: [ConfirmAction],
  data() {
    return {
      servicePosition: null,
    };
  },
  computed: {
    pageTitle() {
      return this.servicePosition ? this.servicePosition.service.name : '';
    },
    serviceTitle() {
      return this.servicePosition
        ? this.$t('page.agent.serviceDetail.title', {
            order: this.servicePosition.order.id,
          })
        : '';
    },
    breadcrumbs() {
      if (this.servicePosition) {
        return [
          { title: this.$t('nav.agent.service'), to: { name: 'agentService' } },
          {
            title: this.serviceTitle,
            to: {
              name: 'agentServiceDetail',
              params: { id: this.servicePosition.order.id },
            },
          },
          { title: this.pageTitle, to: null },
        ];
      }
      return [];
    },
  },
  async mounted() {
    this.addLoadingState();
    try {
      this.servicePosition = await loadPosition(this.$route.params.pos);
    } catch {
      this.$message({
        showClose: true,
        message: this.$t('page.agent.servicePositionDetail.noPositionFound'),
        type: 'error',
      });
      this.$router.push({ name: 'agentService' });
    }
    this.removeLoadingState();
  },
  methods: {
    ...mapMutations('Loading', ['addLoadingState', 'removeLoadingState']),
    confirmAction(payload) {},
  },
};
</script>
<style lang="scss" scoped>
@import '../../../../assets/scss/settings';

.products,
.props {
  margin-bottom: 2rem;
  .el-row {
    margin-bottom: 1rem;
  }
  h2 {
    font-size: $font-size-large;
    margin-bottom: 1rem;
  }
}
</style>
