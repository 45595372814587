<template>
  <div class="product-form">
    <el-form :inline="true">
      <div class="flex-row-center justify-between">
        <el-autocomplete
          v-if="!selectedProduct"
          v-model="form.product"
          size="small"
          :fetch-suggestions="fetchProducts"
          :placeholder="$t('page.agent.servicePositionDetail.searchProducts')"
          :trigger-on-focus="false"
          value-key="code"
          :debounce="500"
          class="product-selector"
          @select="selectProduct"
        >
          <template slot-scope="{ item }">
            <div class="value">{{ item.name }}</div>
          </template>
        </el-autocomplete>
        <div v-else>
          <i class="el-icon-close mr-05" @click="removeSelectedProduct"></i>
          {{ selectedProduct.code }}: {{ selectedProduct.name }} (CHF
          {{ selectedProduct.price }})
        </div>
        <div>
          <el-input-number
            v-model="form.qty"
            size="small"
            :min="1"
            :max="10"
            class="mr-2"
          ></el-input-number>
          <el-button
            type="primary"
            size="small"
            :disabled="!selectedProduct"
            plain
            @click="submitForm"
            >{{ $t('form.control.add') }}</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { searchProducts } from '@/services/Product.service';
export default {
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedProduct: null,
      form: {
        product: null,
        qty: 1,
      },
    };
  },
  methods: {
    async fetchProducts(query, callback) {
      const products = await searchProducts(query);
      callback(products);
    },
    selectProduct(product) {
      this.selectedProduct = product;
    },
    removeSelectedProduct() {
      this.selectedProduct = null;
      this.form = { product: null, qty: 1 };
    },
    submitForm() {},
  },
};
</script>
<style lang="scss">
.product-form {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  margin-bottom: 1rem;
  .product-selector {
    width: 50%;
  }
}
</style>
