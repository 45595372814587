<template>
  <div class="user-account">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-row :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.customerNumber')
          }}</el-col
          ><el-col :span="20">{{ user.customer.bexioNr }}</el-col>
        </el-row>

        <el-row :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.type')
          }}</el-col
          ><el-col :span="20">{{ user.customer.typeDisplay }}</el-col>
        </el-row>

        <el-row :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.email')
          }}</el-col
          ><el-col :span="20">{{ user.email }}</el-col>
        </el-row>

        <el-row :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.phone')
          }}</el-col
          ><el-col :span="20">{{ user.customer.phone }}</el-col>
        </el-row>

        <el-row :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.language')
          }}</el-col
          ><el-col :span="20">{{ user.customer.languageDisplay }}</el-col>
        </el-row>

        <el-row v-if="user.customer.web" :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.web')
          }}</el-col
          ><el-col :span="20">{{ user.customer.web }}</el-col>
        </el-row>

        <el-row v-if="user.customer.swissUid" :gutter="10" class="mb-05">
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.swissUid')
          }}</el-col
          ><el-col :span="20">{{ user.customer.swissUid }}</el-col>
        </el-row>

        <el-row
          v-if="user.customer.type === 'business'"
          :gutter="10"
          class="mb-05"
        >
          <el-col :span="4" class="weight-400">{{
            $t('page.userAccount.contactPerson')
          }}</el-col
          ><el-col :span="20">{{ user.customer.contactName() }}</el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row :gutter="20" class="mt-2">
      <el-col :span="12">
        <h2 class="mb-1 form-section">
          {{ $t('page.userAccount.sectionTitle.shippingAddress') }}
        </h2>
        <div v-if="user.customer.type === 'private'">
          {{ user.customer.salutationDisplay }}
        </div>
        <div>{{ user.customer.fullName() }}</div>
        <div v-if="user.customer.shippingAddress.addition">
          {{ user.customer.shippingAddress.addition }}
        </div>
        <div>{{ user.customer.shippingAddress.street }}</div>
        <div>
          {{ user.customer.shippingAddress.country }}-{{
            user.customer.shippingAddress.zipCode
          }}
          {{ user.customer.shippingAddress.city }}
        </div>
      </el-col>

      <el-col :span="12">
        <h2 class="mb-1 form-section">
          {{ $t('page.userAccount.sectionTitle.invoiceAddress') }}
        </h2>
        <div v-if="user.customer.type === 'private'">
          {{ user.customer.salutationDisplay }}
        </div>
        <div>{{ user.customer.fullName() }}</div>
        <div v-if="user.customer.invoiceAddress.addition">
          {{ user.customer.invoiceAddress.addition }}
        </div>
        <div>{{ user.customer.invoiceAddress.street }}</div>
        <div>
          {{ user.customer.invoiceAddress.country }}-{{
            user.customer.invoiceAddress.zipCode
          }}
          {{ user.customer.invoiceAddress.city }}
        </div>
      </el-col>
    </el-row>

    <!-- todo: Activate this if we would like to show users associated with this account
        <el-row :gutter="20" class="mt-2" v-if="user.type === 'business'">
        <el-col :span="24">
          <h2 class="mb-1 form-section">{{ $t('page.userAccount.sectionTitle.users') }}</h2>
          <UserTable :users="users"></UserTable>
        </el-col>
      </el-row> -->
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
      required: true,
    },
  },
};
</script>
