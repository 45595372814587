<template>
  <div class="page-header__main container">
    <router-link :to="{ name: 'root' }">
      <img src="../../assets/images/logo.svg" class="page-header__logo" />
    </router-link>
    <div class="page-header__controls">
      <LanguageSwitcher />
      <router-link v-if="isLoggedIn" :to="{ name: 'userAccount' }">
        <div class="page-header__profile">
          <img src="../../assets/images/user.svg" class="page-header__icon" />
          <div class="page-header__profile-name">
            {{ user.customer.fullName() }}
          </div>
        </div>
      </router-link>
      <router-link v-if="isLoggedIn" :to="{ name: 'agentIndex' }">
        <div class="page-header__superman">
          <icon-base icon-name="superman" width="28" height="28"
            ><IconSuperman class="page-header__icon"
          /></icon-base>
        </div>
      </router-link>
      <img
        v-if="isLoggedIn"
        :title="$t('page.logout.icon.title')"
        class="page-header__icon"
        src="../../assets/images/logout.svg"
        @click="logout"
      />
    </div>
  </div>
</template>
<script>
import LanguageSwitcher from '@/components/LanguageSwitcher';
import IconSuperman from '@/components/icons/IconSuperman';

import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    LanguageSwitcher,
    IconSuperman,
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters('User', ['isLoggedIn']),
  },
  methods: {
    logout() {
      this.$store.dispatch('User/logout');
      this.$message({
        showClose: true,
        message: this.$t('page.logout.message.logoutSuccess'),
        type: 'success',
      });
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style lang="scss">
@import '../../assets/scss/settings';

.page-header {
  padding: 0.75rem 0 !important;
  background-color: $--color-primary;
}
.page-header__main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header__logo {
  height: 65px;
}
.page-header__controls {
  display: flex;
  flex-direction: row;
}
.page-header__profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  .page-header__icon {
    margin-left: 0;
  }
}
.page-header__profile-name {
  margin-left: 0.5rem;
  color: $--color-white;
  font-size: $font-size-small;
}
.page-header__superman {
  margin-left: 1rem;
}
.page-header__icon {
  width: 1.75rem;
  margin-left: 1.5rem;
  cursor: pointer;
}
</style>
