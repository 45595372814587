<template>
  <el-table
    v-loading="loading"
    :data="customers"
    :show-header="false"
    :empty-text="$t('table.customers.noData')"
    row-class-name="clickable-row"
    @row-click="goTo"
  >
    <el-table-column width="50">
      <template slot-scope="scope">
        <div class="flex">
          <img
            v-if="scope.row.type === 'business'"
            src="../../assets/images/shop.svg"
            class="icon row-icon"
          />
          <img
            v-else
            src="../../assets/images/user_alt.svg"
            class="icon row-icon"
          />
        </div>
      </template>
    </el-table-column>

    <el-table-column width="300" sortable>
      <template slot-scope="scope">
        {{ scope.row.fullName() }}
      </template>
    </el-table-column>

    <el-table-column width="400" sortable>
      <template slot-scope="scope">
        {{ scope.row.address() }}
      </template>
    </el-table-column>

    <el-table-column prop="phone" label="Telefon" width="150">
    </el-table-column>

    <el-table-column>
      <template slot-scope="scope">
        <div v-for="email in scope.row.emailAddresses()" :key="email">
          <a :href="`mailto:${email}`">{{ email }}</a>
        </div>
      </template>
    </el-table-column>

    <el-table-column fixed="right" align="right" width="30">
      <i class="el-icon-arrow-right"></i>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  props: {
    customers: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    goTo(row) {
      this.$router.push({
        name: 'backofficeCustomerDetail',
        params: { id: row.id },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.row-icon {
  height: 30px;
}
</style>
