<template>
  <main-page :page-title="$t('page.editAccount.title')" :loading="isLoading">
    <CustomerEditForm
      v-if="!isLoading && userForCustomer"
      :backoffice="true"
      :user="userForCustomer"
    />
  </main-page>
</template>
<script>
import CustomerEditForm from '@/components/CustomerEditForm';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  components: {
    CustomerEditForm,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('Customer', ['currentCustomer', 'userForCustomer']),
  },
  destroyed() {
    this.setUserForCustomer(null);
  },
  async mounted() {
    await this.getCustomer(parseInt(this.$route.params.id));
    this.isLoading = false;
  },
  methods: {
    ...mapActions('Customer', ['getCustomer']),
    ...mapMutations('Customer', ['setUserForCustomer']),
    createServiceOrder() {
      this.$router.push({ name: 'backofficeOrderCreate' });
    },
  },
};
</script>
