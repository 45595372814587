<template>
  <login-page>
    <div v-loading="isLoading" class="login">
      <div class="login__form">
        <!-- <h1 class="page-title page-title--no-line mb-2">
          {{ $t("page.login.title") }}
        </h1> -->
        <img src="@/assets/images/logo.svg" class="login__logo" />
        <ValidationObserver ref="observer" tag="form">
          <el-form ref="loginForm" :model="form">
            <ValidatedInput
              v-model="form.email"
              label="email"
              rules="required|email"
              :placeholder="$t('form.login.placeholder.email')"
            />

            <ValidatedInput
              v-model="form.password"
              type="password"
              label="password"
              rules="required"
              :placeholder="$t('form.login.placeholder.password')"
              @keyup.enter.native="submitForm"
            />

            <el-form-item>
              <el-button
                type="primary"
                class="el-button--block"
                :disabled="!form.email || !form.password"
                @click="submitForm"
                >{{ $t('page.login.button.login') }}</el-button
              >
            </el-form-item>
          </el-form>
        </ValidationObserver>

        <div class="login__controls">
          <div>
            <router-link :to="{ name: 'createAccount' }">{{
              $t('page.login.createAccount')
            }}</router-link>
          </div>
          <div class="h-text-right">
            <!-- Removed this for Web module submission as this function is not implemented yeat
            <router-link :to="{ name: 'forgotPassword' }">{{ $t('page.login.forgotPassword') }}</router-link>
            -->
          </div>
        </div>
      </div>
    </div>
  </login-page>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';

export default {
  components: {
    ValidationObserver,
    ValidatedInput,
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: '',
        password: '',
      },
    };
  },
  mounted() {
    if (this.$route.query.state === 'activated') {
      this.$message({
        showClose: true,
        message: this.$t('page.login.message.activateSuccess'),
        type: 'success',
      });
    }
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.isLoading = true;
        this.login();
      }
    },
    async login() {
      try {
        await this.$store.dispatch('User/login', this.form);
        this.$message({
          showClose: true,
          message: this.$t('page.login.message.loginSuccess'),
          type: 'success',
        });
        this.$router.push({ name: 'root' });
      } catch (e) {
        this.isLoading = false;
        this.$message({
          showClose: true,
          message: this.$t('page.login.message.loginError'),
          type: 'error',
          duration: 15000,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  &__logo {
    margin-bottom: 2rem;
    width: 80%;
  }
}
.login__form {
  width: 100%;
  max-width: 400px;
}
.login__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
