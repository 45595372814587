<template>
  <main-page :page-title="$t('page.createServiceOrder.title')">
    <ServiceOrderForm
      :customer="currentCustomer.id"
      redirect-route="backofficeOrderDetail"
    />
  </main-page>
</template>
<script>
import ServiceOrderForm from '@/components/ServiceOrderForm';
import { mapState } from 'vuex';

export default {
  components: {
    ServiceOrderForm,
  },
  computed: {
    ...mapState('Customer', ['currentCustomer']),
  },
  mounted() {
    if (!this.currentCustomer) {
      this.$router.push({ name: 'backofficeDashboard' });
    }
  },
};
</script>
