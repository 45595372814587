<template>
  <main-page
    :page-title="$t('page.backoffice.contact.title')"
    :loading="isLoading"
  >
    <template v-slot:page-controls>
      <div
        v-if="!isLoading && currentCustomer"
        class="flex-row-center justify-between"
      >
        <el-button
          type="primary"
          size="small"
          class="mr-1"
          @click="createServiceOrder"
        >
          {{ $t('page.backoffice.customerDetail.controls.newServiceOrder') }}
        </el-button>
        <router-link
          v-if="userForCustomer"
          :to="{
            name: 'backofficeCustomerEdit',
            params: { id: currentCustomer.id },
          }"
        >
          <el-button type="default" size="small">
            {{ $t('page.backoffice.customerDetail.controls.editCustomer') }}
          </el-button>
        </router-link>
      </div>
    </template>

    <UserDetail v-if="userForCustomer" :user="userForCustomer" />
  </main-page>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import UserDetail from '@/components/UserDetail';

export default {
  components: {
    UserDetail,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState('Customer', ['currentCustomer', 'userForCustomer']),
  },
  destroyed() {
    this.setUserForCustomer(null);
  },
  async mounted() {
    await this.getCustomer(parseInt(this.$route.params.id));
    this.isLoading = false;
  },
  methods: {
    ...mapActions('Customer', ['getCustomer']),
    ...mapMutations('Customer', ['setUserForCustomer']),
    createServiceOrder() {
      this.$router.push({ name: 'backofficeOrderCreate' });
    },
  },
};
</script>
