<template>
  <el-dropdown placement="bottom" @command="setLang">
    <span class="el-dropdown-link">
      <img src="../assets/images/locale.svg" class="switcher__icon" />
      <div class="switcher__lang">{{ $i18n.locale }}</div>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        v-for="(lang, i) in langs"
        :key="`Lang${i}`"
        :command="lang"
        :disabled="lang == $i18n.locale"
        >{{ lang }}</el-dropdown-item
      >
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: 'LanguageSwitcher',
  data() {
    return { langs: ['de', 'en'] }; // Removed for Web module submission { langs: ['de', 'en', 'fr', 'it'] }
  },
  methods: {
    ...mapMutations('User', ['setLanguage']),
    setLang(lang) {
      this.$i18n.locale = lang || 'de';
      this.setLanguage(lang);
    },
  },
};
</script>
<style lang="scss">
@import '../assets/scss/settings';
.el-dropdown-link {
  color: $--color-white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1.5rem;
}
.el-dropdown-menu__item {
  text-transform: uppercase;
}
.switcher__icon {
  height: 1.75rem;
  cursor: pointer;
}
.switcher__lang {
  margin-left: 0.5rem;
  color: $--color-white;
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
}
</style>
