import { unpack } from './helpers';
import http from './http';
import axios from 'axios';

import {
  Manufacture,
  Service,
  ShippingMethod,
  PaymentMethod,
  ServiceOrder,
  ServicePosition,
  Insurance,
} from '@/models/ServiceOrder.model';

const CancelToken = axios.CancelToken;
let cancel;

// Load a certan service order from the API
// and map it to a ServiceOrder object
export const loadServiceOrder = async (id) => {
  const response = await unpack(http.get(`/order/${id}/`));
  return new ServiceOrder(response);
};

// Load a list of service orders from the API
// and map each to a ServiceOrder object
export const loadServiceOrders = async (page, term) => {
  let params = {};
  if (page !== 1) params.page = page;
  if (term !== '') params.search = term;

  // cancel previous requests
  cancel && cancel();

  try {
    const response = await unpack(
      http.get('/order/', {
        params: params,
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
      })
    );
    return {
      count: response.count,
      orders: response.results.map((payload) => {
        return new ServiceOrder(payload);
      }),
    };
  } catch (err) {}
};

// Load all manufactures from the API
// and map them to a Manufacture object
export const loadManufactures = async (type) => {
  const response = await unpack(
    http.get('/manufacture/', { params: { type: type } })
  );
  return response.map((payload) => {
    return new Manufacture(payload);
  });
};

// Load all shipping insurances from the API
// and map them to a Insurance object
export const loadInsurances = async () => {
  const response = await unpack(http.get('/insurance/'));
  return response.map((payload) => {
    return new Insurance(payload);
  });
};

// Load all services from the API filtered by manufacture
// and map them to a Service object
export const loadServices = async (type, manufacture) => {
  const response = await unpack(
    http.get('/service/', {
      params: {
        type: type,
        'manufacture-id': manufacture,
      },
    })
  );
  return response.map((payload) => {
    return new Service(payload);
  });
};

// Load all shipping methods from teh API
// and map them to a ShippingMethod object
export const loadShippingMethods = async () => {
  const response = await unpack(http.get('/shipping-method/'));
  return response.map((payload) => {
    return new ShippingMethod(payload);
  });
};

// Load all payment methods from teh API
// and map them to a ShippingMethod object
export const loadPaymentMethods = async () => {
  const response = await unpack(http.get('/payment-method/'));
  return response.map((payload) => {
    return new PaymentMethod(payload);
  });
};

// Create new service order through the API
// and map the created service order to a ServiceOrder object
export const createOrder = async (formData) => {
  const response = await unpack(
    http.post('/order/', {
      customer: formData.customer,
      shippingMethod: formData.shippingMethod.id,
      paymentMethod: formData.paymentMethod.id,
      isExpress: formData.isExpress,
    })
  );
  return new ServiceOrder(response);
};

// Update an existing ServiceOrder through the API
// and map the updated service order to a ServiceOrder object
export const updateOrder = async (formData) => {
  const response = await unpack(
    http.put(`/order/${formData.id}/`, {
      customer: formData.customer.id,
      shippingMethod: formData.shippingMethod.id,
      paymentMethod: formData.paymentMethod.id,
      isExpress: formData.isExpress,
    })
  );
  return new ServiceOrder(response);
};

// Delete and existing ServiceOrder
export const deleteOrder = async (id) => {
  const response = await unpack(http.delete(`/order/${id}/`));
  return response;
};

// Create a new position on a service order and return
// a ServicePosition opject
export const createPosition = async (formData) => {
  const response = await unpack(http.post('/position/', formData));
  return new ServicePosition(response);
};

// Get a position on a service order and return
// a ServicePosition opject
export const loadPosition = async (id) => {
  const response = await unpack(http.get(`/position/${id}/`));
  return new ServicePosition(response);
};

// Delete and existing ServicePosition
export const deletePosition = async (id) => {
  const response = await unpack(http.delete(`/position/${id}/`));
  return response;
};

// Update a position on a service order
export const updatePosition = async (position) => {
  const response = await unpack(
    http.put(`/position/${position.id}/`, position)
  );
  return response;
};

// Patch a service order and return a ServiceOrder object
export const patchServiceOrder = async (order, formData) => {
  const response = await unpack(http.patch(`/order/${order.id}/`, formData));
  return new ServiceOrder(response);
};
