<template>
  <plane-page :page-title="$t('page.createAccount.title')">
    <CustomerCreateForm />
  </plane-page>
</template>
<script>
import CustomerCreateForm from '@/components/CustomerCreateForm';

export default {
  components: {
    CustomerCreateForm,
  },
};
</script>
