import store from '@/store/';

/**
 * Is current user logged in?
 * @return {Boolean}
 */
export const isLoggedIn = () => store.getters['User/isLoggedIn'];

/**
 * Is current user a staff member?
 * @return {Boolean}
 */
export const isStaff = () => isLoggedIn() && store.getters['User/isStaff'];

/**
 * Is current user an admin?
 * @return {Boolean}
 */
export const isAdmin = () => isLoggedIn() && store.getters['User/isAdmin'];
