<template>
  <ValidationObserver ref="observer" tag="form">
    <el-form
      ref="loginForm"
      :model="form"
      class="select-position-form"
      @submit.prevent.native="submitForm"
    >
      <ValidatedInput
        v-model="form.serviceCode"
        type="number"
        label="serviceCode"
        rules="required"
        :placeholder="$t('page.agent.service.placeholder.order')"
        pattern="[0-9]*"
        class="position-input"
      />
      <el-row>
        <el-col>
          <el-form-item>
            <el-button
              type="primary"
              class="el-button--block"
              @click="submitForm"
              >{{ $t('page.agent.service.showServiceOrder') }}</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import ValidatedInput from '@/components/inputs/ValidatedInput';

export default {
  components: {
    ValidationObserver,
    ValidatedInput,
  },
  data() {
    return {
      form: {
        serviceCode: '',
      },
    };
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$emit('select', this.form.serviceCode);
      } else {
        this.$message({
          message: this.$t('validation.formError'),
          type: 'error',
        });
      }
    },
  },
};
</script>

<style lang="scss">
.select-position-form {
  display: flex;
  justify-content: space-between;
  .position-input {
    flex-grow: 0.95;
  }
}
</style>
