import axios from 'axios';
import { Message } from 'element-ui';

import store from '@/store';
import config from '@/config/config';
import router from '@/router';
import i18n from '@/i18n';

import _ from 'lodash';

const http = axios.create({
  baseURL: config.API_URL,
});

/**
 * Include the JWT token in the request when the user is logged in
 */
http.interceptors.request.use((config) => {
  if (store.getters['User/isLoggedIn']) {
    config.headers.common[
      'Authorization'
    ] = `Bearer ${store.state.User.accessToken}`;
  }
  config.headers.common['Accept-Language'] = i18n.locale;
  config.headers.common['Content-Language'] = i18n.locale;

  return config;
});

/**
 * Handle API errors
 */
http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle 401/403 unauthorized erros - they could for example occur when a token is expired
    if ([401, 403].indexOf(error.response.status) !== -1) {
      store.dispatch('User/logout');
      Message({
        showClose: true,
        message: i18n.t('general.message.tokenExpired'),
        type: 'error',
      });
      router.push({ name: 'login' });
    }
    throw error;
  }
);

export default http;
