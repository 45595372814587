<template>
  <el-container class="main-layout el-container--fullheight">
    <el-header height="auto" class="page-header">
      <page-header />
    </el-header>

    <el-main v-loading="isLoading">
      <page-navigation />
      <div class="page-main container">
        <el-row
          type="flex"
          justify="space-between"
          class="mb-1 title-row title-row__border-bottom"
        >
          <div class="flex-row-center justify-between">
            <slot name="page-navigation"></slot>
            <h1 class="page-title">{{ pageTitle }}</h1>
          </div>
          <slot name="page-controls"></slot>
        </el-row>
        <slot></slot>
      </div>
    </el-main>

    <el-footer class="page-footer">
      <page-footer> </page-footer>
    </el-footer>
  </el-container>
</template>
<script>
export default {
  name: 'MainPage',
  props: {
    pageTitle: {
      type: String,
      required: false,
      default: '',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/scss/settings';

.page-main {
  padding-top: 1.5rem !important;
}
.title-row {
  align-items: center;
  &__border-bottom {
    border-bottom: 1px solid $--border-color-base;
    width: 100%;
    padding-bottom: 10px;
  }
}
</style>
