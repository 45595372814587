class Customer {
  constructor(payload) {
    if (!payload) return;

    this.id = payload && payload.id ? payload.id : null;
    this.bexioNr = payload && payload.bexioNr ? payload.bexioNr : '';
    this.type = payload && payload.type ? payload.type : null;
    this.typeDisplay =
      payload && payload.getTypeDisplay ? payload.getTypeDisplay : null;
    this.salutation = payload && payload.salutation ? payload.salutation : null;
    this.salutationDisplay =
      payload && payload.getSalutationDisplay
        ? payload.getSalutationDisplay
        : null;
    this.firstName = payload && payload.firstName ? payload.firstName : null;
    this.lastName = payload && payload.lastName ? payload.lastName : null;
    this.companyName =
      payload && payload.companyName ? payload.companyName : null;
    this.companyAddition =
      payload && payload.companyAddition ? payload.companyAddition : null;
    this.phone = payload && payload.phone ? payload.phone : null;
    this.swissUid = payload && payload.swissUid ? payload.swissUid : null;
    this.web = payload && payload.web ? payload.web : null;
    this.language = payload && payload.language ? payload.language : null;
    this.languageDisplay =
      payload && payload.getLanguageDisplay ? payload.getLanguageDisplay : null;

    this.shippingAddress =
      payload && payload.shippingAddress ? payload.shippingAddress : null;
    this.invoiceAddress =
      payload && payload.invoiceAddress ? payload.invoiceAddress : null;

    this.users = payload && payload.users ? payload.users : [];
  }

  fullName() {
    if (this.type === 'business') {
      return `${this.companyName || ''}`;
    } else {
      return `${this.firstName || ''} ${this.lastName || ''}`;
    }
  }

  contactName() {
    if (this.type === 'business') {
      return `${this.firstName || ''} ${this.lastName || ''}`;
    } else {
      return this.fullName();
    }
  }

  address() {
    return `${this.shippingAddress.street}, ${this.shippingAddress.zipCode} ${this.shippingAddress.city}`;
  }

  emailAddresses() {
    return this.users.map((user) => {
      return user.email;
    });
  }
}

export default Customer;
