<template>
  <main-page
    :page-title="
      $t('page.dashboard.greeting') + ' ' + user.customer.contactName()
    "
  >
  </main-page>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('User', ['user']),
  },
};
</script>
