<template>
  <div class="property">
    <div class="label">{{ label }}</div>
    <div class="value">{{ value }}</div>
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss">
@import '../../../assets/scss/settings';

.property {
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-radius: 7px;
  .label {
    color: $--color-gray;
    margin-bottom: 0.5rem;
  }
  .value {
    font-size: 1.15rem;
  }
}
</style>
