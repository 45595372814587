<template>
  <span></span>
</template>
<script>
export default {
  created() {
    this.$store.dispatch('User/logout');
    this.$router.push({ name: 'login' });
  },
};
</script>
